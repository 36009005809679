import { updateUserCache } from "~graphql/cache/users";
import { useMutation } from "~graphql/hooks/shared";
import { SEND_TRANSACTIONAL_SMS_OPT_IN } from "~graphql/mutations/transactionalSmsConsent";

export const useSendTransactionalSmsOptIn = () => {
    const [sendTransactionalSmsOptInMutation, { error, loading }] = useMutation(
        SEND_TRANSACTIONAL_SMS_OPT_IN
    );

    const sendTransactionalSmsOptIn = () => {
        sendTransactionalSmsOptInMutation({
            update(cache, { data }) {
                const update = { consent: data.sendTransactionalSmsOptIn };
                updateUserCache({
                    cache,
                    update,
                });
            },
        });
    };

    return { sendTransactionalSmsOptIn, error, isLoading: loading };
};

import {
    ApolloProvider as ApolloProviderOG,
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import DebounceLink from "apollo-link-debounce";
import { getUserLocale } from "get-user-locale";
import PropTypes from "prop-types";
import React from "react";

import { isDevEnvironment } from "~app/helpers";
import { useAuthToken } from "~app/hooks/authHooks";
import { getTypePolicies } from "~app/typePolicyHelpers";
import { DEFAULT_DEBOUNCE_TIMEOUT, X_CLIENT_TYPE } from "~constants/constants";
import packageJson from "~root/package.json";

const httpLink = createHttpLink({
    uri: process.env.API_URL,
});

export default function ApolloProvider({ children }) {
    const token = useAuthToken();
    const client = getApolloClient({ token });

    return <ApolloProviderOG client={client}>{children}</ApolloProviderOG>;
}

ApolloProvider.propTypes = {
    children: PropTypes.any,
};

function getApolloClient({ token }) {
    const cleanTypeName = getCleanTypeName();
    const headersLink = getHeadersLink({ token });

    const link = ApolloLink.from([
        new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
        cleanTypeName,
        headersLink,
        httpLink,
    ]);

    return new ApolloClient({
        connectToDevTools: isDevEnvironment,
        link,
        cache: new InMemoryCache({
            addTypename: true,
            typePolicies: getTypePolicies(),
        }),
    });
}

function getHeadersLink({ token }) {
    const xClientVersion = packageJson.version;
    const xLocale = getUserLocale();

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                "x-client-version": xClientVersion,
                "x-client-type": X_CLIENT_TYPE,
                "x-locale": xLocale,
                authorization: token ? `Bearer ${token}` : "",
            },
        };
    });

    return authLink;
}

function getCleanTypeName() {
    // removes __typename from objects for mutations that use existing query data
    // eslint-disable-next-line no-secrets/no-secrets
    // https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses
    const cleanTypeName = new ApolloLink((operation, forward) => {
        if (operation.variables) {
            const omitTypename = (key, value) =>
                key === "__typename" ? undefined : value;

            operation.variables = JSON.parse(
                JSON.stringify(operation.variables),
                omitTypename
            );
        }

        return forward(operation).map((data) => {
            return data;
        });
    });

    return cleanTypeName;
}

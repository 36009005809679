import { isFunction } from "@boomnation/lib-common";

export function convertUnixToDatePicker(date) {
    if (!date) return null;

    const localDateString = new Date(date * 1000).toLocaleDateString("en-US");

    return new Date(localDateString);
}

export function getIsHidden({ config, values }) {
    const { isHidden } = config;

    return getConfigValue({ valueOrFunction: isHidden, values });
}

export function getWidthOptions({ config, values }) {
    const isHalfWidth = getConfigValue({
        valueOrFunction: config.isHalfWidth,
        values,
    });
    const isQuarterWidth = getConfigValue({
        valueOrFunction: config.isQuarterWidth,
        values,
    });
    const isSixthWidth = getConfigValue({
        valueOrFunction: config.isSixthWidth,
        values,
    });

    return { isHalfWidth, isQuarterWidth, isSixthWidth };
}

// isHidden and isHalfWidth can be a bool or function that returns a
// bool. Function gets called with form values.
function getConfigValue({ valueOrFunction, values }) {
    if (isFunction(valueOrFunction)) {
        return valueOrFunction(values);
    }

    return valueOrFunction;
}

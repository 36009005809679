import styled from "@emotion/styled";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

import { TEST_IDS } from "~constants/constants";

export default function ErrorMessage({
    error,
    onButtonClick,
    buttonLabel = "Retry",
    ...props
}) {
    if (!error) return null;

    const message = error.message
        ? error.message
        : "Oops! Something went wrong...";

    return (
        <ErrorBox data-testid={TEST_IDS.ERROR_WITH_RETRY} {...props}>
            <StyledErrorIcon />
            <Message>{message}</Message>
            <Button
                color="secondary"
                variant="contained"
                onClick={() => onButtonClick()}
            >
                {buttonLabel}
            </Button>
        </ErrorBox>
    );
}

ErrorMessage.propTypes = {
    error: PropTypes.object,
    onButtonClick: PropTypes.func,
    buttonLabel: PropTypes.string,
};

const StyledErrorIcon = styled(ErrorIcon)({
    fontSize: "70px",
});

const ErrorBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 50,
});

const Message = styled(Typography)({
    marginBottom: 15,
    marginTop: 15,
});

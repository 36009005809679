import * as yup from "yup";

import { FORM_ERROR } from "~constants/constants";
import { isSmsUser } from "~services/authHelpers";

export function getEmailValidator(user) {
    const defaultEmailValidation = yup.string().email(FORM_ERROR.EMAIL);

    const emailValidation = isSmsUser(user)
        ? defaultEmailValidation.nullable()
        : defaultEmailValidation.required(FORM_ERROR.EMAIL);

    return emailValidation;
}

import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

export const paperOverrides = {
    minWidth: "100%",
    zIndex: 100,
    position: "absolute",
    top: "100%",
    overflow: "scroll",
    maxHeight: 150,
};

export const menuListOverrides = {
    display: "flex",
    flexDirection: "column",
};

export const AddressText = styled(Typography)({
    display: "inline-flex",
    fontWeight: 600,
});

export const AddressText2 = styled(Typography)({
    display: "inline-flex",
    marginLeft: 5,
});

export const GoogleLogo = styled.img({
    alignSelf: "flex-end",
    marginRight: 10,
});

export function formatPhoneFromApi(phone) {
    return (phone || "").replace("+1", "");
}

export function formatPhoneForApi(phone) {
    if (!phone) return null;

    // Remove any possible `+1` prefix or invalid characters like (-).
    const phoneWithoutPrefix = formatPhoneFromApi(phone);

    // Sonarcloud rule: https://rules.sonarsource.com/javascript/RSPEC-6353
    const cleanPhone = phoneWithoutPrefix.replace(/\D/g, "");

    return `+1${cleanPhone}`;
}

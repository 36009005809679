import styled from "@emotion/styled";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Box from "@mui/material/Box";
import React from "react";

export default function UploadCameraIcon(props) {
    return (
        <Container display="flex" justifyContent="center" alignItems="center">
            <CameraIcon {...props} />
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.colors.icon.neutralContrast,
    borderRadius: "100%",
    padding: 6,
}));

const CameraIcon = styled(CameraAltIcon)(({ theme }) => ({
    fontSize: "25px",
    color: theme.palette.colors.icon.neutral,
    backgroundColor: theme.palette.colors.icon.neutralContrast,
}));

const COMMON_CERTIFICATION_PROPS = `
    id
    name
    description
    created_at
    updated_at
`;

export const CERTIFICATIONS = `
    ${COMMON_CERTIFICATION_PROPS}
    fields
`;

export const CERTIFICATION_FIELD = `
    key
    label
    field_type {
        type
        field_options {
            entity_type
            values {
                id
                name
            }
        }
    }
    icon {
        mobile_icon
    }
    db_prop
    is_required
`;

export const CERTIFICATION = `
    ${COMMON_CERTIFICATION_PROPS}
    fields {
        ${CERTIFICATION_FIELD}
    }
`;

import { LOCAL_STORAGE_KEYS } from "~constants/constants";

export function getJobsSearchLocation() {
    return readObjectFromLocalStorage({ key: LOCAL_STORAGE_KEYS.LOCATION });
}

export function setJobsSearchLocation(location) {
    return writeObjectToLocalStorage({
        key: LOCAL_STORAGE_KEYS.LOCATION,
        value: location,
    });
}

function writeObjectToLocalStorage({ key, value }) {
    localStorage.setItem(key, JSON.stringify(value));
}

function readObjectFromLocalStorage({ key }) {
    const value = localStorage.getItem(key);

    return parseJsonSafely(value);
}

function parseJsonSafely(str) {
    try {
        return JSON.parse(str);
    } catch {
        return null;
    }
}

export function writeForwardingState(value) {
    const state = readForwardingState();

    // if no state or if it points to home overwrite it
    if (!state || (state && state.returnTo === "/")) {
        writeObjectToLocalStorage({
            key: LOCAL_STORAGE_KEYS.FORWARDING,
            value,
        });

        return true;
    }

    return false;
}

export function readForwardingState() {
    const state = readObjectFromLocalStorage({
        key: LOCAL_STORAGE_KEYS.FORWARDING,
    });

    return state;
}

export function removeForwardingState() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.FORWARDING);
}

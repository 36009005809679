import { CERTIFICATION_FIELD } from "~graphql/schemas/certificationsSchema";

export const PROFILE_COMPLETION_PERCENTAGE = `
    percentage
`;

export const CERTIFICATION = `
    id
    user_id
    name
    number
    photo_url
    status
    fields {
        value {
            value_type
            string_value
            timestamp_value
            entity_value {
                entity_id
                entity_type
            }
            id_name_value {
                id
                name
            }
            id_name_values {
                id
                name
            }
        }
        ${CERTIFICATION_FIELD}
    }
    effective_at
    created_at
    updated_at
`;

export const EDUCATION = `
    id
    user_id
    degree_name
    location_description
    school {
        id
        name
    }
    photo_url
    start_at
    end_at
    created_at
    updated_at
`;

export const EMPLOYMENT = `
    id
    user_id
    position
    location_description
    start_at
    end_at
    created_at
    updated_at
    place_of_employment {
        id
        name
    }
    photo_url
`;

export const RESUME = `
    id
    user_id
    name
    description
    attachment_id
    attachment_mime
    created_at
    updated_at
`;

export const CARD = `
    id
    user_id
    name
    description
    attachment_id
    created_at
    updated_at
`;

export const USER_TRADE = `
    id
    trade {
        id
        name
    }
    years_of_experience
    additional_details {
        id
        name
    }
    industries {
        id
        name
    }
    is_primary
    created_at
    updated_at
`;

export const USER_LOCATION = `
    line_1
    line_2
    city
    state
    zip
`;

export const USER_COMPANY = `
    id
    name
    role
    image_url
    features
`;

export const CONSENT = `
    marketing {
        has_consented
    }
    transactional {
        has_consented
    }
`;

export const VACCINATION = `
    id
    user_id
    name
    manufacturer
    first_dose_at
    second_dose_at
    latest_booster_at
    created_at
    updated_at
`;

export const USER = `
    id
    email
    first_name
    last_name
    photo_url
    cover_url
    blocked
    phone_number
    is_available_for_work
    bio
    is_internal
    certifications {
        ${CERTIFICATION}
    }
    employment {
        ${EMPLOYMENT}
    }
    education {
        ${EDUCATION}
    }
    user_trades {
        ${USER_TRADE}
    }
    resumes {
        ${RESUME}
    }
    location {
        ${USER_LOCATION}
    }
    cards {
        ${CARD}
    }
    vaccinations {
        ${VACCINATION}
    }
    companies {
        ${USER_COMPANY}
    }
    consent {
        ${CONSENT}
    }
    created_at
    updated_at
`;

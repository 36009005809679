import { isString } from "@boomnation/lib-common";
import styled from "@emotion/styled";
import { Input } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { useAttachmentRemoteUrl } from "~app/hooks/cdnHooks";
import { FORM_INPUTS } from "~constants/constants";
import InputTitle from "~shared/Form/Inputs/InputTitle";
import { FullWidthFormControl } from "~shared/Form/Inputs/styledFormComponents";

export default function ImageUploadInput({
    id,
    value,
    validationMessage,
    onChange,
    title,
    EmptyComponent,
    centered = false,
}) {
    const { remoteUrl } = useAttachmentRemoteUrl({
        attachmentId: isString(value) ? value : null,
    });

    const [image, setImage] = useState();

    const ContainerComponent = FullWidthFormControl;

    return (
        <ContainerComponent>
            <InputTitle>{title}</InputTitle>
            <StyledLabel htmlFor={id} centered={centered}>
                <EmptyComponent id={id} image={image || remoteUrl} />
            </StyledLabel>
            <UploadInput
                id={id}
                aria-label={id}
                onChange={(event) =>
                    handleOnChange({
                        onChange,
                        event: event.target.files[0],
                        setImage,
                    })
                }
                error={!!validationMessage}
                type="file"
                inputProps={{
                    "data-testid": FORM_INPUTS.IMAGE,
                }}
            />
        </ContainerComponent>
    );
}

ImageUploadInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    validationMessage: PropTypes.string,
    title: PropTypes.string,
    EmptyComponent: PropTypes.func,
    centered: PropTypes.bool,
};

const StyledLabel = styled.label(({ centered }) => ({
    ...(centered && { display: "flex", justifyContent: "center" }),
}));

const UploadInput = styled(Input)(() => ({
    display: "none",
}));

function handleOnChange({ onChange, event, setImage }) {
    setImage(URL.createObjectURL(event));
    onChange(event);
}

import gql from "graphql-tag";

import { SEND_TRANSACTIONAL_SMS_OPT_IN_RESULT } from "~graphql/schemas/transactionalSmsConsentSchema";

export const SEND_TRANSACTIONAL_SMS_OPT_IN = gql`
    mutation Mutation {
        sendTransactionalSmsOptIn {
            ${SEND_TRANSACTIONAL_SMS_OPT_IN_RESULT}
        }
    }
`;

import PropTypes from "prop-types";
import { useEffect } from "react";

export default function RedirectToExternal({ url, location, match }) {
    useEffect(() => {
        // After sign up/in with auth0, the user is redirected to "/" before
        // the loginWithRedirect logic sends them to the final route.
        // So, we don't want to redirect in this situation.
        const isHomeRoute = match.path === "/";
        const hasAuth0Code = location.search.startsWith("?code=");

        if (isHomeRoute && hasAuth0Code) return;

        window.location.replace(url);
    }, []);

    return null;
}

RedirectToExternal.propTypes = {
    url: PropTypes.string,
    location: PropTypes.shape({ search: PropTypes.string }),
    match: PropTypes.shape({ path: PropTypes.string }),
};

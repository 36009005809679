import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

import SectionHeader from "~shared/Page/SectionHeader";

export default function PageSectionHeader({
    title,
    headerContent,
    hideHeaderBorder,
}) {
    // if no title is passed, child component uses own custom header using SectionHeader
    if (!title) return null;

    return (
        <SectionHeader hideHeaderBorder={hideHeaderBorder}>
            <FlexGrowBox>
                <SectionTitle variant="h4">{title}</SectionTitle>
                <Box>{headerContent}</Box>
            </FlexGrowBox>
        </SectionHeader>
    );
}

PageSectionHeader.propTypes = {
    title: PropTypes.string,
    hideHeaderBorder: PropTypes.bool,
    headerContent: PropTypes.node,
};

const SectionTitle = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(1),
    marginLeft: 0,
}));

const FlexGrowBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
    },
}));

import queryString from "query-string";

import { LINK_TARGET } from "~constants/constants";

export const isDevEnvironment = process.env.ENVIRONMENT !== "production";

// Extracts new_tab param to decide link target and adds back any other params.
export function getHrefAndTargetFromContentfulUrl(url) {
    if (!url) return {};

    const [baseUrl, paramsString] = url.split("?");
    const { new_tab: newTab, ...otherParams } = queryString.parse(paramsString);
    const otherParamsString = queryString.stringify(otherParams);

    const target = !newTab ? undefined : LINK_TARGET.NEW_TAB;
    const href = otherParamsString
        ? `${baseUrl}?${otherParamsString}`
        : baseUrl;

    return { href, target };
}

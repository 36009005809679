import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, { useState } from "react";

import TranslateButton from "~shared/Button/TranslateButton";
// import parse from "html-react-parser";

export default function TextWithTranslateButton({
    text,
    i18n,
    propertyName,
    TextComponent = Box,
    textComponentStyle = {},
}) {
    const [translatableText, setTranslatableText] = useState(text);

    // Arcoro jobs Effect to ensure the initial text is set properly
    // function decodeHtml(html) {
    //     const textArea = document.createElement("textarea");
    //     textArea.innerHTML = html;
    //     return textArea.value;
    // }
    // const decodedHtmlContent = decodeHtml(translatableText)
    return (
        <Box>
            {/* {parse(decodedHtmlContent)} */}
            <TextComponent style={textComponentStyle}>
                {translatableText}
                {/* {parse(decodedHtmlContent)} */}
            </TextComponent>
            <TranslateButton
                text={translatableText}
                onTranslated={setTranslatableText}
                propertyName={propertyName}
                i18n={i18n}
            />
        </Box>
    );
}

TextWithTranslateButton.propTypes = {
    i18n: PropTypes.array,
    // text: PropTypes.string,
    text: PropTypes.string.isRequired,
    propertyName: PropTypes.string,
    TextComponent: PropTypes.elementType,
    textComponentStyle: PropTypes.object,
};

import { Modal } from "@boomnation/lib-web-common";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import { noop } from "~utils/utils";

export default function PromptForProfileInfoModalContainer({
    isOpen,
    title,
    subtitle,
    children,
}) {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={noop} hideCloseButton>
            <ModalHeader>
                <Typography variant="h4">{t(title)}</Typography>
                <Typography>{t(subtitle)}</Typography>
            </ModalHeader>
            {children}
        </Modal>
    );
}

PromptForProfileInfoModalContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.any,
};

const ModalHeader = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.card.divider}`,
    padding: "0px 20px",
    marginBottom: "10px",
}));

import { useIsAuthenticated, useAuth } from "~app/hooks/authHooks";
import { updateUserCache } from "~graphql/cache/users";
import { useQuery, useMutation } from "~graphql/hooks/shared";
import { UPDATE_USER } from "~graphql/mutations/users";
import {
    GET_CURRENT_USER,
    GET_USER,
    GET_USER_PROFILE_COMPLETION_PERCENTAGE,
} from "~graphql/queries/users";

export const useCurrentUser = () => {
    const isAuthenticated = useIsAuthenticated();

    const { error, loading, data, refetch } = useQuery(GET_CURRENT_USER, {
        skip: !isAuthenticated,
    });
    const user = data?.getCurrentUser;

    return { error, isLoading: loading, user, refetch };
};

export const useGetUser = (id) => {
    const isAuthenticated = useIsAuthenticated();

    const { error, loading, data, refetch } = useQuery(GET_USER, {
        variables: {
            getUserId: id,
        },
        skip: !isAuthenticated || !id,
    });

    const user = data?.getUser;

    return { error, isLoading: loading, user, refetch };
};

export const useUserId = () => {
    const { user } = useCurrentUser();

    return user?.id;
};

export const useIsPublicProfile = ({ user }) => {
    const currentUserId = useUserId();

    return currentUserId !== user?.id;
};

export const useUpdateUser = ({ onCompleted, onError }) => {
    const { user } = useCurrentUser();

    const [updateUserMutation, { error, loading }] = useMutation(UPDATE_USER, {
        onCompleted,
        onError,
    });

    const updateUser = (userUpdate) => {
        updateUserMutation({
            variables: {
                id: user?.id,
                update: userUpdate,
            },
            update(cache, { data }) {
                updateUserCache({ cache, update: data.updateUser });
            },
        });
    };

    return { updateUser, error, isLoading: loading };
};

export function useProfileCompletionPercentage() {
    const userId = useUserId();

    const { loading, error, data, refetch } = useQuery(
        GET_USER_PROFILE_COMPLETION_PERCENTAGE,
        {
            variables: { id: userId },
            skip: !userId,
        }
    );
    const percentage = data?.getUserProfileCompletionPercentage.percentage;

    return { percentage, isLoading: loading, error, refetch };
}

// The user query won't fire until auth is completely loaded, so
// we need to factor this into the loading state.
export function useIsCompanyMember(companyId) {
    const { isLoading: isAuthLoading } = useAuth();
    const { user, isLoading: isUserLoading, error } = useCurrentUser();

    const hasCompanyRole = !!user?.companies.find(({ id }) => id === companyId);
    const isMember = user?.is_internal || hasCompanyRole;
    const isLoading = isUserLoading || isAuthLoading;

    return { isMember, isLoading, error };
}

import gql from "graphql-tag";

import {
    USER,
    PROFILE_COMPLETION_PERCENTAGE,
} from "~graphql/schemas/userSchemas";

export const GET_CURRENT_USER = gql`
    query Query {
        getCurrentUser {
            ${USER}
        }
    }
`;

export const GET_USER = gql`
    query GetUser($getUserId: String!) {
        getUser(id: $getUserId) {
            ${USER}
        }
    }
`;

export const GET_USER_PROFILE_COMPLETION_PERCENTAGE = gql`
    query Query($id: String!) {
        getUserProfileCompletionPercentage(id: $id) {
            ${PROFILE_COMPLETION_PERCENTAGE}
        }
    } 
`;

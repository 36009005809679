import { ADDRESS } from "~graphql/schemas/addresses";

export const PROJECT = `
    id
    company_id
    name
    description
    location {
        ${ADDRESS}
    }
    is_archived
    archived_at
    users_count
    content_blocks_count
    created_at
    updated_at
`;

/* eslint max-lines: ["error", {"max": 250}] */
import loadable from "@loadable/component";
import React from "react";

import { PAGES, ROUTES } from "~constants/constants";
import DeepLinksHubPage from "~pages/DeepLinksHubPage";
import RedirectToExternal from "~pages/RedirectToExternal";

const loadableOpts = { fallback: <div /> };

const Landing = loadable(() => import("~pages/Landing/Landing"), loadableOpts);

const WhyBoomnation = loadable(
    () => import("~pages/Landing/WhyBoomnation"),
    loadableOpts
);

const Company = loadable(() => import("~pages/Landing/Company"), loadableOpts);

const Collection = loadable(
    () => import("~pages/CollectionPage"),
    loadableOpts
);

// Jobs
const JobDetails = loadable(
    () => import("~pages/JobDetails/JobDetails"),
    loadableOpts
);
const Jobs = loadable(() => import("~pages/Jobs"), loadableOpts);

const ApplicationSuccess = loadable(
    () => import("~pages/ApplicationSuccess/ApplicationSuccess"),
    loadableOpts
);

const Profile = loadable(() => import("~pages/Profile/Profile"), loadableOpts);

const PublicProfile = loadable(
    () => import("~pages/Profile/PublicProfile"),
    loadableOpts
);

const ProjectDetails = loadable(
    () => import("~pages/ProjectDetails/ProjectDetails"),
    loadableOpts
);

// Company
const CompanyForJobSeekerPage = loadable(
    // eslint-disable-next-line no-secrets/no-secrets
    () => import("~pages/CompanyForJobSeeker/CompanyForJobSeekerPage"),
    loadableOpts
);

const CompanyForWorker = loadable(
    () => import("~pages/CompanyForWorker/CompanyForWorker"),
    loadableOpts
);

const Newsfeed = loadable(() => import("~pages/Newsfeed"), loadableOpts);

const Messages = loadable(() => import("~pages/Messages"), loadableOpts);

const SimplePage = loadable(() => import("~pages/SimplePage"), loadableOpts);

const WelcomeCompanyWorker = loadable(
    () => import("~pages/WelcomeCompanyWorker"),
    loadableOpts
);

const WelcomeProjectWorker = loadable(
    () => import("~pages/WelcomeProjectWorker"),
    loadableOpts
);

const WorkerProjectReferralPage = loadable(
    () => import("~pages/WorkerProjectReferralPage"),
    loadableOpts
);

const SavingsCalculator = loadable(
    () => import("~pages/SavingsCalculator/SavingsCalculator"),
    loadableOpts
);

const CampaignForms = loadable(
    () => import("~pages/CampaignForms/CampaignForms"),
    loadableOpts
);

export default [
    {
        key: PAGES.LANDING,
        path: ROUTES.LANDING,
        component: Landing,
        exact: true,
    },
    {
        key: PAGES.WHY_BOOMNATION,
        path: ROUTES.WHY_BOOMNATION,
        component: WhyBoomnation,
        exact: true,
    },
    {
        key: PAGES.COMPANY,
        path: ROUTES.COMPANY,
        component: Company,
        exact: true,
    },
    {
        key: PAGES.COLLECTION,
        path: ROUTES.COLLECTION,
        component: Collection,
        exact: true,
    },
    // Jobs
    {
        key: PAGES.JOB_DETAILS,
        path: ROUTES.JOB_DETAILS,
        component: JobDetails,
        exact: true,
    },
    {
        key: PAGES.JOBS,
        path: ROUTES.JOBS,
        component: Jobs,
        exact: true,
    },
    {
        key: PAGES.APPLICATION_SUCCESS,
        path: ROUTES.APPLICATION_SUCCESS,
        component: ApplicationSuccess,
        exact: true,
    },
    // Profile
    {
        key: PAGES.PROFILE,
        path: ROUTES.PROFILE,
        component: Profile,
        exact: true,
        isProtected: true,
    },
    // User's Profile
    {
        key: PAGES.USER_PROFILE,
        path: ROUTES.USER_PROFILE,
        component: PublicProfile,
        exact: true,
        isProtected: true,
    },
    // Companies
    {
        key: PAGES.COMPANY_FOR_JOB_SEEKER,
        path: ROUTES.COMPANY_FOR_JOB_SEEKER,
        component: CompanyForJobSeekerPage,
        exact: true,
    },
    {
        key: PAGES.COMPANY_PROJECTS,
        path: ROUTES.COMPANY_PROJECTS,
        component: CompanyForWorker,
        exact: true,
        isProtected: true,
    },
    // Deeplink hub
    {
        key: PAGES.DEEP_LINK_HUB,
        path: ROUTES.DEEP_LINK_HUB,
        component: DeepLinksHubPage,
        exact: true,
    },
    // Project Details
    {
        key: PAGES.PROJECT_DETAILS,
        path: ROUTES.PROJECT_DETAILS,
        component: ProjectDetails,
        exact: true,
        isProtected: true,
    },
    // Messages
    {
        key: PAGES.MESSAGES,
        path: ROUTES.MESSAGES,
        component: Messages,
        exact: true,
    },
    // Newsfeed
    {
        key: PAGES.NEWSFEED,
        path: ROUTES.NEWSFEED,
        component: Newsfeed,
        exact: true,
    },
    // Simple Pages
    {
        key: PAGES.SIMPLE_PAGES,
        path: ROUTES.SIMPLE_PAGES,
        component: SimplePage,
        exact: true,
    },
    // Welcome Workers
    {
        key: PAGES.COMPANY_PROJECTS_WELCOME,
        path: ROUTES.COMPANY_PROJECTS_WELCOME,
        component: WelcomeCompanyWorker,
        exact: true,
        isProtected: true,
    },
    {
        key: PAGES.PROJECT_DETAILS_WELCOME,
        path: ROUTES.PROJECT_DETAILS_WELCOME,
        component: WelcomeProjectWorker,
        exact: true,
        isProtected: true,
    },
    {
        key: PAGES.PROJECT_WORKER_REFERRALS,
        path: ROUTES.PROJECT_WORKER_REFERRALS,
        component: WorkerProjectReferralPage,
        exact: true,
    },
    {
        key: PAGES.SAVINGS_CALCULATOR,
        path: ROUTES.SAVINGS_CALCULATOR,
        component: SavingsCalculator,
        exact: true,
    },
    {
        key: PAGES.CAMPAIGN_FORM,
        path: ROUTES.CAMPAIGN_FORM,
        component: CampaignForms,
        exact: true,
    },
    // Default (redirects to boomnation.com)
    {
        key: PAGES.DEFAULT,
        // eslint-disable-next-line react/display-name
        render: (props) => (
            <RedirectToExternal
                {...props}
                url={process.env.BOOMNATION_WEBSITE_URL}
            />
        ),
    },
];

import React from "react";
import { render } from "react-dom";
import "@fontsource/roboto/400.css";

import App from "~app/App";
import Providers from "~app/Providers";
import { sentryConfig, withProfiler } from "~services/sentry";
import "~services/i18n";

const WrappedApp = withProfiler(App);

sentryConfig();

render(
    <Providers>
        <WrappedApp />
    </Providers>,
    document.getElementById("root")
);

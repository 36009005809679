import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

import {
    AddressText,
    AddressText2,
} from "~shared/Form/Inputs/styledAddressFormInput";

export default function AddressPlacesSuggestions({ data, handleSelect }) {
    return data.map((suggestion) => {
        const {
            place_id: placeId,
            structured_formatting: { main_text: street, secondary_text: city },
        } = suggestion;

        return (
            <MenuItem
                onMouseDown={(event) => event.preventDefault()}
                key={placeId}
                onClick={handleSelect(suggestion)}
            >
                <ListItemText>
                    <AddressText variant="body2">{street}</AddressText>
                    <AddressText2 variant="caption"> {city}</AddressText2>
                </ListItemText>
            </MenuItem>
        );
    });
}

import React from "react";
import "yup-phone-lite"; // TODO should we move away from this in favor of a simple mask
import * as yup from "yup";

import { FORM_ERROR } from "~constants/constants";
import FormTextInput from "~shared/Form/Inputs/FormTextInput";

export default function PhoneNumberInput({ ...props }) {
    return <FormTextInput {...props} />;
}

export function getPhoneNumberValidator() {
    return yup
        .string()
        .ensure()
        .phone("US", FORM_ERROR.PHONE)
        .required(FORM_ERROR.PHONE);
}

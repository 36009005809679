import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";

export function StyledTextField({ ...props }) {
    return <CustomTextField {...props} />;
}

export const FullWidthFormControl = styled(FormControl, {
    shouldForwardProp: isPropValid,
})(({ noBottomMargin }) => ({
    width: "100%",
    flex: 1,
    marginBottom: noBottomMargin ? "0" : "20px",
}));

export const InputWidth = styled(Box, { shouldForwardProp: isPropValid })(
    ({ isHalfWidth, isQuarterWidth, isSixthWidth, addBottomMargin }) => ({
        width: isHalfWidth ? "50%" : "100%",
        marginBottom: addBottomMargin ? "30px" : "0px",
        ...(isQuarterWidth && {
            width: "25%",
        }),
        ...(isSixthWidth && {
            width: "16.66%",
        }),
        padding:
            (isHalfWidth || isQuarterWidth || isSixthWidth) &&
            "0px 15px 0px 0px",
        display:
            (isHalfWidth || isQuarterWidth || isSixthWidth) && "inline-flex",
    })
);

export const StyledAutocomplete = styled(Autocomplete)({
    "& input": {
        padding: "4px 6px !important",
    },
    "& .MuiAutocomplete-endAdornment": {
        top: "calc(50% - 16px)",
    },
});

const CustomTextField = styled(TextField)(({ theme }) => ({
    margin: "2px",
    "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    },
    "& input": {
        padding: "6px",
    },
    "& textarea": {
        padding: "6px",
    },
    "& label": {
        padding: "0 6px",
        color: theme.palette.text.primary,
    },
    ".MuiFormHelperText-root": {
        color: "red",
    },
}));

/* eslint max-lines: ["error", {"max": 350}] */
import { removeUndefinedAndReturnNullIfEmpty } from "@boomnation/lib-common";
import { AnalyticsBrowser } from "@segment/analytics-next";

import { ANALYTICS_EVENTS, JOB_SOURCE } from "~constants/constants";
import googleAnalytics from "~services/googleAnalytics";
import { logError } from "~services/sentry";

const analytics = AnalyticsBrowser.load({
    writeKey: process.env.SEGMENT_WRITE_KEY,
});

async function sendTrackEvent({
    eventName,
    entity,
    mapperFunc,
    extraAttributes,
}) {
    await runCoreFuncWithErrorHandling({
        coreFunc: sendTrackEventCore,
        coreFuncArgs: { eventName, entity, mapperFunc, extraAttributes },
    });
}

async function sendIdentifyEvent(user) {
    await runCoreFuncWithErrorHandling({
        coreFunc: sendIdentifyEventCore,
        coreFuncArgs: { user },
    });
}

async function sendPageEvent({ url, path }) {
    await runCoreFuncWithErrorHandling({
        coreFunc: sendPageEventCore,
        coreFuncArgs: { url, path },
    });
}

async function sendReferralSubmittedEvent(referral) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.REFERRAL_SUBMITTED,
        entity: referral,
        mapperFunc: mapReferralAttributesForAnalytics,
    });
}

async function sendSavingsCalculatedEvent(savingsValues) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.SAVINGS_CALCULATED,
        entity: savingsValues,
        mapperFunc: mapSavingsAttributesForAnalytics,
    });
}

function sendTrackApplyOnExternalLinkPressed(job) {
    return sendTrackEvent({
        eventName: ANALYTICS_EVENTS.APPLY_ON_EXTERNAL_LINK_PRESSED,
        entity: job,
        mapperFunc: mapJobAttributesForAnalytics,
    });
}

async function sendJobViewedEvent(job) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.JOB_VIEWED,
        entity: job,
        mapperFunc: mapJobAttributesForAnalytics,
    });
}

async function sendCompanyViewedEvent(company) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.COMPANY_VIEWED,
        entity: company,
        mapperFunc: mapCompanyAttributesForAnalytics,
    });
}

async function sendJobApplicationStartedEvent(job) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.JOB_APPLICATION_STARTED,
        entity: job,
        mapperFunc: mapJobAttributesForAnalytics,
        extraAttributes: { source: JOB_SOURCE.JOBS },
    });
}

async function sendJobApplicationCompletedEvent(job) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.JOB_APPLICATION_COMPLETED,
        entity: job,
        mapperFunc: mapJobAttributesForAnalytics,
        extraAttributes: { source: JOB_SOURCE.JOBS },
    });
}

async function sendSharedJobViewedEvent(job) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.SHARED_JOB_VIEWED,
        entity: job,
        mapperFunc: mapJobAttributesForAnalytics,
    });
}

async function sendScrollToBulletinPostSuccessEvent() {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.SCROLL_TO_BULLETIN_POST_SUCCESS,
    });
}

async function sendScrollToBulletinPostFailureEvent() {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.SCROLL_TO_BULLETIN_POST_FAILURE,
    });
}

async function sendGoToMyProjectLandingButtonClickEvent() {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.GO_TO_MY_PROJECT_LANDING_BUTTON_CLICKED,
    });
}

async function sendProjectLinkFollowedEvent({ project, linkType }) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.PROJECT_LINK_FOLLOWED,
        entity: project,
        mapperFunc: mapProjectForAnalytics,
        extraAttributes: { linkType },
    });
}

async function sendJobSearchEvent({
    searchKeyword,
    location,
    pay,
    tradesAndIndustries,
    totalJobs,
}) {
    const eventName = totalJobs
        ? ANALYTICS_EVENTS.JOB_SEARCH_PERFORMED
        : ANALYTICS_EVENTS.NO_JOBS_FOUND;

    await sendTrackEvent({
        eventName,
        entity: {
            searchKeyword,
            location,
            pay,
            tradesAndIndustries,
            totalJobs,
        },
        mapperFunc: mapJobSearchParams,
    });
}

async function sendAcceptedCompanyInvitationEvent(userCompany) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.ACCEPTED_COMPANY_INVITATION,
        entity: userCompany,
        mapperFunc: mapUserCompanyForAnalytics,
    });
}

function trackExternalLink({
    anchorElement,
    eventName = ANALYTICS_EVENTS.EXTERNAL_LINK_CLICKED,
}) {
    analytics.trackLink(anchorElement, eventName);
}

async function trackShareLink({
    anchorElement,
    eventName = ANALYTICS_EVENTS.SHARE_CLICKED,
    entityType,
    entityId,
    sharedOn,
}) {
    await analytics.trackLink(anchorElement, eventName, {
        entity_type: entityType,
        entity_id: entityId,
        shared_on: sharedOn,
    });
}

// CORE FUNCS

async function sendIdentifyEventCore({ user }) {
    const traits = mapUserAttributesForAnalytics(user);

    await analytics.identify(user.id, traits);
}

async function sendTrackEventCore({
    eventName,
    entity,
    mapperFunc,
    extraAttributes,
}) {
    const entityAttributes = mapperFunc ? mapperFunc(entity) : entity;
    const attributes = removeUndefinedAndReturnNullIfEmpty({
        ...entityAttributes,
        ...extraAttributes,
    });
    const args = attributes ? [eventName, attributes] : [eventName];

    await analytics.track(...args);
}

async function sendPageEventCore({ url, path }) {
    await analytics.page({ url, path });
    googleAnalytics.trackPageView({ url, path });
}

async function runCoreFuncWithErrorHandling({ coreFunc, coreFuncArgs }) {
    try {
        await coreFunc(coreFuncArgs);
    } catch (error) {
        logError({ error });
    }
}

// MAPPERS

function mapUserAttributesForAnalytics(user) {
    return {
        firstName: user.first_name,
        lastName: user.last_name,
        phone: user.phone_number,
        email: user.email,
        is_internal: user.is_internal,
    };
}

function mapReferralAttributesForAnalytics(referral) {
    return {
        referral_id: referral.id,
        referral_referrer_id: referral.referrer_id,
        referral_company_id: referral.company_id,
        referral_entity_id: referral.entity_id,
        referral_entity_type: referral.entity_type,
    };
}

function mapSavingsAttributesForAnalytics(savingsValues) {
    return {
        ...savingsValues.recruiting,
        ...savingsValues.retention,
        ...savingsValues.total,
        ...savingsValues.savings,
    };
}

function mapJobAttributesForAnalytics(job) {
    return {
        job_id: job.id,
        job_name: job.name,
        job_company_id: job.company.id,
    };
}

function mapCompanyAttributesForAnalytics(company) {
    return {
        company_id: company.id,
        company_name: company.name,
    };
}

function mapUserCompanyForAnalytics(userCompany) {
    return {
        company_id: userCompany.id,
        company_name: userCompany.name,
        role: userCompany.role,
    };
}

function mapProjectForAnalytics(project) {
    return {
        project_id: project.id,
        project_name: project.name,
        company_id: project.company_id,
    };
}

export function mapJobSearchParams({
    searchKeyword,
    location,
    pay,
    tradesAndIndustries,
    totalJobs,
}) {
    return removeUndefinedAndReturnNullIfEmpty({
        search_keyword: searchKeyword,
        trade_name: tradesAndIndustries?.[0]?.tradeName,
        location_latitude: location?.latitude,
        location_longitude: location?.longitude,
        location_radius: location?.radius,
        pay_max: pay?.max,
        pay_min: pay?.min,
        total_jobs: totalJobs,
    });
}

export default {
    sendIdentifyEvent,
    sendPageEvent,
    sendJobViewedEvent,
    sendJobApplicationStartedEvent,
    sendJobApplicationCompletedEvent,
    trackExternalLink,
    sendCompanyViewedEvent,
    sendJobSearchEvent,
    sendSharedJobViewedEvent,
    sendAcceptedCompanyInvitationEvent,
    sendScrollToBulletinPostSuccessEvent,
    sendScrollToBulletinPostFailureEvent,
    sendProjectLinkFollowedEvent,
    trackShareLink,
    sendReferralSubmittedEvent,
    sendTrackApplyOnExternalLinkPressed,
    sendSavingsCalculatedEvent,
    sendGoToMyProjectLandingButtonClickEvent,
};

import { useTheme } from "@boomnation/lib-web-common";
import SkeletonOG from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import React from "react";

import { TEST_IDS } from "~constants/constants";

export default function Skeleton({ isRounded, style = {}, ...props }) {
    const theme = useTheme();

    return (
        <SkeletonOG
            {...props}
            data-testid={TEST_IDS.SKELETON}
            sx={getOverrides({ theme, isRounded, style })}
        />
    );
}
// Had to overwrite skeletons original styles using the sx prop
function getOverrides({ theme, isRounded, style }) {
    return {
        borderRadius: isRounded ? theme.borderRadius.sm : undefined,
        ...style,
    };
}
Skeleton.propTypes = {
    isRounded: PropTypes.bool,
    style: PropTypes.object,
};

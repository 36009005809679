import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

export default function InputTitle({ children, variant = "h5", ...props }) {
    return (
        <Typography {...props} variant={variant} m={1}>
            {children}
        </Typography>
    );
}

InputTitle.propTypes = {
    children: PropTypes.string,
    variant: PropTypes.string,
};

import { gql } from "@apollo/client";

import { PROJECT } from "~graphql/schemas/projects";

export const GET_WORKER_PROJECTS = gql`
    query Query(
        $companyId: UUID!
        $searchKeyword: String
        $limit: PositiveInt
        $offset: NonNegativeInt
        $sort: SortInput
    ) {
        getWorkerProjects(
            companyId: $companyId
            searchKeyword: $searchKeyword
            limit: $limit
            offset: $offset
            sort: $sort
        ) {
            results {
                ${PROJECT}
            }
            meta {
                total
            }
        }
    }
`;

export const GET_WORKER_ASSIGNED_PROJECTS = gql`
    query Query(
        $limit: PositiveInt
        $offset: NonNegativeInt
        $sort: SortInput
    ) {
        getWorkerAssignedProjects(
            limit: $limit
            offset: $offset
            sort: $sort
        ) {
            results {
                ${PROJECT}
                company_image_url
            }
            meta {
                total
            }
        }
    }
`;

export const GET_PROJECT = gql`
    query getProject($id: UUID!) {
        getProject(id: $id) {
            ${PROJECT}
        }
    }
`;

export const GET_PROJECT_NAME = gql`
    query getProjectName($id: UUID!) {
        getProjectName(id: $id) {
            id
            name
            company_name
            company_image_url
        }
    }
`;

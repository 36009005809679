import { FORM_INPUTS, FORM_INPUT_LABELS } from "~constants/constants";
import AddressFormTextInput from "~shared/Form/Inputs/AddressFormTextInput";
import FormTextInput from "~shared/Form/Inputs/FormTextInput";
import AvatarImageInput from "~shared/Form/Inputs/ImageUploadInput/AvatarImageInput";
import PhoneNumberInput from "~shared/Form/Inputs/PhoneNumberInput";

export function getEditWorkFormSections({ isSmScreen }) {
    const inputConfigs = [
        {
            title: FORM_INPUT_LABELS.FIRST_NAME,
            path: "first_name",
            id: FORM_INPUTS.FIRST_NAME,
            isHalfWidth: !isSmScreen,
            Component: FormTextInput,
        },
        {
            title: FORM_INPUT_LABELS.LAST_NAME,
            path: "last_name",
            id: FORM_INPUTS.LAST_NAME,
            isHalfWidth: !isSmScreen,
            Component: FormTextInput,
        },
        {
            title: FORM_INPUT_LABELS.LOCATION,
            path: "location",
            label: "Nashville, TN",
            id: FORM_INPUTS.LOCATION,
            isHalfWidth: !isSmScreen,
            Component: AddressFormTextInput,
            getComponentProps: () => ({ variant: "standard" }),
        },
        {
            title: FORM_INPUT_LABELS.EMAIL,
            path: "email",
            id: FORM_INPUTS.EMAIL,
            isHalfWidth: !isSmScreen,
            Component: FormTextInput,
        },
        {
            title: FORM_INPUT_LABELS.PHONE_NUMBER,
            path: "phone_number",
            id: FORM_INPUTS.PHONE,
            isHalfWidth: !isSmScreen,
            Component: PhoneNumberInput,
        },
        {
            title: FORM_INPUT_LABELS.PROFILE_IMAGE,
            path: "photo_url",
            id: FORM_INPUTS.IMAGE,
            isHalfWidth: !isSmScreen,
            Component: AvatarImageInput,
            getComponentProps: () => ({ centered: true }),
        },
    ];

    return [{ subsections: inputConfigs, noMarginTop: true }];
}

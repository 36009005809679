import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import React from "react";

export default function Button(props) {
    return <StyledButton {...props}>{props.children}</StyledButton>;
}

Button.propTypes = {
    children: PropTypes.string,
};

const StyledButton = styled(LoadingButton)(({ theme, padding }) => ({
    borderRadius: theme.borderRadius.lg,
    padding: padding || "2px 45px",
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.bold,
    boxShadow: "none !important",
    overflow: "hidden",
}));

import React from "react";

import { useAuth, useLoginWithRedirect } from "~app/hooks/authHooks";
import RouteWithPageAnalytics from "~app/RouteWithPageAnalytics";

export default function ProtectedRouteWithPageAnalytics(props) {
    const loginWithRedirect = useLoginWithRedirect();
    const { isAuthenticated, hasBeenAuthenticated, isLoading } = useAuth();

    const shouldLoginWithRedirect =
        !isAuthenticated && !hasBeenAuthenticated && !isLoading;

    if (shouldLoginWithRedirect) {
        loginWithRedirect();

        return null;
    }

    return <RouteWithPageAnalytics {...props} />;
}

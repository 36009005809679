import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useNavigationHelpers } from "~app/navigationHelpers";
import { useGetWorkerAssignedProjects } from "~graphql/hooks/projects";
import { useCurrentUser } from "~graphql/hooks/users";
import {
    readForwardingState,
    removeForwardingState,
} from "~services/localStorage";

export function useForwarding() {
    const { user, isLoading } = useCurrentUser();
    const { projects, isLoading: isProjectsLoading } =
        useGetWorkerAssignedProjects();
    const isAnythingLoading = isLoading || isProjectsLoading;

    const { goToCompanyProjects, goToProject } = useNavigationHelpers();

    const history = useHistory();

    const [state] = useState(readForwardingState());
    removeForwardingState();

    useEffect(() => {
        const shouldBail = isAnythingLoading || !user || !state;

        // If we are loading or don't have the user bail on any attempt to route
        if (shouldBail) return;

        // If only forwarding to home page check if user has a company and send them there
        if (state.returnTo === "/") {
            redirectToWorkerDefault({
                user,
                projects,
                goToCompanyProjects,
                goToProject,
            });

            return;
        }

        // else forward to the stored state
        history.replace({
            pathname: state.returnTo,
            search: state.searchString,
        });
    }, [isAnythingLoading]);
}

function redirectToWorkerDefault({
    user,
    projects,
    goToCompanyProjects,
    goToProject,
}) {
    // If we have a project go to that
    if (projects?.length) return goToProject({ projectId: projects[0].id });

    // Else fall back to the company worker page
    return redirectToCompanyPage({ user, goToCompanyProjects });
}

function redirectToCompanyPage({ user, goToCompanyProjects }) {
    if (!user.companies[0]) return;

    const companyId = user.companies[0].id;
    goToCompanyProjects({ companyId });
}

import { omit } from "@boomnation/lib-common";

import {
    readQuery,
    writeQuery,
    upsertEntityInArray,
    removeEntityFromArray,
    replaceEntityInArray,
} from "~graphql/cache/shared";
import { GET_CURRENT_USER } from "~graphql/queries/users";

export function addToUserCache({ cache, entity, field }) {
    const user = getUserFromCache({ cache });

    const newFieldValue = upsertEntityInArray({
        entityArray: user[field],
        entity,
    });
    const update = { [field]: newFieldValue };

    updateUserCache({ cache, update });
}

export function removeFromUserCache({ cache, entityId, field }) {
    const user = getUserFromCache({ cache });

    const newFieldValue = removeEntityFromArray({
        entityArray: user[field],
        id: entityId,
    });
    const update = { [field]: newFieldValue };

    updateUserCache({ cache, update });
}

export function updateItemInUserCache({ cache, replacementEntity, field }) {
    const user = getUserFromCache({ cache });

    const newFieldValue = replaceEntityInArray({
        entityArray: user[field],
        replacementEntity,
    });
    const update = { [field]: newFieldValue };

    updateUserCache({ cache, update });
}

export function updateUserCache({ cache, update }) {
    const user = getUserFromCache({ cache });

    updateUserCacheCore({ cache, update, user });
}

export function updateUserCacheCore({ cache, update, user }) {
    const updatedUser = { ...user, ...omit(update, ["__typename"]) };

    writeQuery({
        ...getDefaultUserCacheQueryArgs(cache),
        update: updatedUser,
    });
}

function getUserFromCache({ cache }) {
    const queryArgs = getDefaultUserCacheQueryArgs(cache);

    return readQuery(queryArgs);
}

function getDefaultUserCacheQueryArgs(cache) {
    return {
        cache,
        query: GET_CURRENT_USER,
        pathToData: "getCurrentUser",
    };
}

import { Auth0Provider } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React from "react";

export default function AuthProvider({ children }) {
    return (
        <Auth0Provider
            domain={process.env.AUTH0_DOMAIN}
            clientId={process.env.AUTH0_CLIENT_ID}
            audience={process.env.AUTH0_AUDIENCE}
            redirectUri={window.location.origin}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    );
}

AuthProvider.propTypes = {
    children: PropTypes.any,
};

import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import React from "react";
import { hot } from "react-hot-loader/root";

import { useUserAnalytics } from "~app/hooks/analyticsHooks";
import { useTriggerOptIn } from "~app/hooks/consentHooks";
import { useForwarding } from "~app/hooks/forwardingHooks";
import RootTooltip from "~app/RootTooltip";
import Routes from "~app/Routes";
import { TEST_IDS } from "~constants/constants";
import PromptForProfileInfoModal from "~shared/Prompts/PromptForProfileInfoModal";

export default hot(App);

function App() {
    useUserAnalytics();
    useForwarding();
    useTriggerOptIn();

    return (
        <AppContainer data-testid={TEST_IDS.APP}>
            <Routes />
            <PromptForProfileInfoModal />
            <RootTooltip />
        </AppContainer>
    );
}

const AppContainer = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    minHeight: "100vh",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
}));

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import pkg from "~root/package.json";

export const { withProfiler } = Sentry;

export function sentryConfig() {
    Sentry.init(getSentryOptions());
}

function getSentryOptions() {
    const options = {
        dsn: process.env.SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.ENVIRONMENT,
        release: pkg.version,
        // Sentry recommends having a lower rate for production
        tracesSampleRate: process.env.ENVIRONMENT === "production" ? 0.5 : 1.0,
    };

    return options;
}

export function logError({ error, extra = {} }) {
    Sentry.withScope((scope) => {
        scope.setExtras(extra);
        if (extra?.code) {
            scope.setTag("error.code", extra.code);
        }

        Sentry.captureException(error);
    });
}

export function logMessage({ message, extra = {} }) {
    Sentry.withScope((scope) => {
        scope.setExtras(extra);
        if (extra?.isRedirectMessage) {
            scope.setTag("isRedirectMessage", true);
        }
        if (extra?.traceId) {
            scope.setTag("traceId", extra.traceId);
        }

        Sentry.captureMessage(message);
    });
}

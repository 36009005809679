import { getGeocode, getZipCode, getLatLng } from "use-places-autocomplete";

import { FORM_ERROR } from "~constants/constants";

export const getAddress = async (description) => {
    try {
        const results = await getGeocode({ address: description });
        const zip = await getZipCode(results[0], false);
        const { lat, lng } = await getLatLng(results[0]);
        const addressParts = [...results[0].formatted_address.split(", ")];

        return mapAddressPartsToAddress({ addressParts, zip, lat, lng });
    } catch (err) {
        if (err.message === FORM_ERROR.FULL_ADDRESS) {
            throw err;
        }
        throw new Error(FORM_ERROR.GOOGLE_PLACES);
    }
};

function mapAddressPartsToAddress({ addressParts, zip, lat, lng }) {
    const adjustedAddressParts = validateAndAdjustAddressParts(addressParts);

    return {
        line_1: adjustedAddressParts[0],
        city: adjustedAddressParts[1],
        state: adjustedAddressParts[2].slice(0, 2),
        zip,
        latitude: lat,
        longitude: lng,
    };
}

function validateAndAdjustAddressParts(addressParts) {
    // Throw error if address doesn't have at least city/state.
    if (addressParts.length < 3) {
        throw new Error(FORM_ERROR.FULL_ADDRESS);
    }

    // Some addresses have a "premise" before the street address. If there are
    // more than 4 parts, remove the premise so the array starts with the street address.
    if (addressParts.length > 4) {
        return addressParts.slice(1);
    }

    // For city/state only addresses, add null as first item to represent line_1.
    if (addressParts.length === 3) {
        return [null, ...addressParts];
    }

    return addressParts;
}

import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRouteWithPageAnalytics from "~app/ProtectedRouteWithPageAnalytics";
import routes from "~app/routesConfig";
import RouteWithPageAnalytics from "~app/RouteWithPageAnalytics";

export default function Routes() {
    return (
        <Switch>
            {routes.map(({ isProtected, ...page }) => {
                const RouteComp = isProtected
                    ? ProtectedRouteWithPageAnalytics
                    : RouteWithPageAnalytics;

                return (
                    // Disable eslint rule for line as `key` comes from page
                    // eslint-disable-next-line react/jsx-key
                    <RouteComp {...page} />
                );
            })}
        </Switch>
    );
}

import { isObject, isEmpty } from "@boomnation/lib-common";

import { formatPhoneForApi } from "~graphql/formatHelpers";
import { getPermissionsForAnyCompanyAsset } from "~services/cdn";
import { logError } from "~services/sentry";

export function getInitialValues(user) {
    if (!user) return null;

    return {
        first_name: user.first_name,
        last_name: user.last_name,
        location: user.location,
        email: user.email,
        phone_number: user.phone_number,
        photo_url: user.photo_url,
    };
}

async function uploadPhotoAndFormatValuesSafe({
    values,
    uploadAttachmentSafely,
}) {
    try {
        const formattedValues = await uploadPhotoAndFormatValues({
            values,
            uploadAttachmentSafely,
        });

        return formattedValues;
    } catch (error) {
        logError({ error });

        return null;
    }
}

async function uploadPhotoAndFormatValues({ values, uploadAttachmentSafely }) {
    const profileImage = values.photo_url;
    const newValues = { ...values };
    // Do not try to upload if the current value is a string. A value set to a string is an existing value
    if (isObject(profileImage)) {
        const { id: photoId } = await uploadAttachmentSafely({
            attachment: profileImage,
            permissions: getPermissionsForAnyCompanyAsset(),
        });

        newValues.photo_url = photoId;
    }

    if (newValues.phone_number) {
        newValues.phone_number = formatPhoneForApi(newValues.phone_number);
    }

    // if user clears email, set to null
    if (newValues.email === "") {
        newValues.email = null;
    }

    return newValues;
}

export async function handleSubmit({
    values,
    uploadAttachmentSafely,
    updateUser,
}) {
    const formattedValues = await uploadPhotoAndFormatValuesSafe({
        values,
        uploadAttachmentSafely,
    });

    if (isEmpty(formattedValues)) {
        return null;
    }
    const updatedUser = await updateUser(formattedValues);

    return updatedUser;
}

import { getUserLocale } from "get-user-locale";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { resources } from "~services/translations";

// Only grab language portion
const lng = getUserLocale().slice(0, 2);

i18n.use(initReactI18next).init({
    resources,
    lng,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    // TODO enable logging once we've added all missing translations.
    // We know there are some missing for profile forms
    // missingKeyHandler: (lngs, ns, key, fallbackValue) => {
    //     const error = new Error(
    //         `Missing translation: ${key}. Fallback used: ${fallbackValue}`
    //     );
    //     logError({ error });
    // },
    saveMissing: true,
});

export default i18n;

import { LibWebCommonProviders } from "@boomnation/lib-web-common";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import React from "react";
import { Router } from "react-router-dom";

import ApolloProvider from "~app/ApolloProvider";
import AuthProvider from "~app/AuthProvider";
import {
    useAttachmentRemoteUrl as useAttachmentImage,
    useDownloadAttachmentsZip,
} from "~app/hooks/cdnHooks";
import { useCurrentUser } from "~graphql/hooks/users";
import analytics from "~services/analytics";
import { logError } from "~services/sentry";

const history = createBrowserHistory();

export default function Providers({ children }) {
    const util = {
        logError,
        useAttachmentImage,
        useCurrentUser,
        useDownloadAttachmentsZip,
        analytics,
        workerWebUrl: process.env.WORKER_WEB_URL,
    };

    return (
        <AuthProvider>
            <LibWebCommonProviders util={util}>
                <ApolloProvider>
                    <Router history={history}>{children}</Router>
                </ApolloProvider>
            </LibWebCommonProviders>
        </AuthProvider>
    );
}

Providers.propTypes = {
    children: PropTypes.any,
};

import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";
import { useTranslation as useTranslationi18n } from "react-i18next";

import { useIsAuthenticated } from "~app/hooks/authHooks";
import { TEST_IDS } from "~constants/constants";
import { COPY } from "~constants/copyConstants";
import { useTranslation } from "~graphql/hooks/translations";

export default function TranslateButton({
    text,
    propertyName,
    i18n,
    onTranslated,
}) {
    const isAuthenticated = useIsAuthenticated();
    const {
        i18n: { language: preferredLanguage },
        t,
    } = useTranslationi18n();

    const [isTranslated, setIsTranslated] = useState(false);
    const [originalText] = useState(text);

    const { translate, isLoading, translation } = useTranslation(() => {
        setIsTranslated(true);
        onTranslated(translation.translated_text);
    });

    // only show translate button if text exists, i18n is present, and text is not in preferred language
    const isButtonHidden = useMemo(
        () =>
            shouldHideTranslateButton({
                i18n,
                propertyName,
                preferredLanguage,
                text,
            }),
        [i18n, propertyName, preferredLanguage, text]
    );

    if (isButtonHidden || !isAuthenticated) {
        return null;
    }

    return (
        <Button
            disableRipple
            data-testid={TEST_IDS.TRANSLATE_BUTTON}
            loading={isLoading}
            disabled={isLoading}
            onClick={() =>
                translateOrShowOriginal({
                    originalText,
                    translate,
                    onTranslated,
                    isTranslated,
                    setIsTranslated,
                })
            }
        >
            {t(getTranslateLabel(isTranslated))}
        </Button>
    );
}

TranslateButton.propTypes = {
    text: PropTypes.string.isRequired,
    onTranslated: PropTypes.func.isRequired,
    propertyName: PropTypes.string.isRequired,
    i18n: PropTypes.arrayOf(
        PropTypes.shape({
            property_name: PropTypes.string.isRequired,
            source_language: PropTypes.string.isRequired,
        })
    ),
};

function translateOrShowOriginal({
    originalText,
    translate,
    onTranslated,
    isTranslated,
    setIsTranslated,
}) {
    if (!isTranslated) return translate({ text: originalText });

    setIsTranslated(false);

    return onTranslated(originalText);
}

function getTranslateLabel(isTranslated) {
    if (isTranslated) return COPY.SHOW_ORIGINAL.EN;

    return COPY.TRANSLATE.EN;
}

function isTextInPreferredLanguage({ i18n, propertyName, preferredLanguage }) {
    const propertyI18n = i18n.find(
        (i18nInstance) => i18nInstance.property_name === propertyName
    );
    const propertySourceLanguage = propertyI18n?.source_language;

    return preferredLanguage === propertySourceLanguage;
}

function shouldHideTranslateButton({
    i18n,
    propertyName,
    preferredLanguage,
    text,
}) {
    const doesNotHaveTextOrI18n = !text || !i18n;

    return (
        doesNotHaveTextOrI18n ||
        isTextInPreferredLanguage({ i18n, propertyName, preferredLanguage })
    );
}

const Button = styled(LoadingButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    variant: "text",
    fontSize: theme.typography.subtitle2.fontSize,
    alignSelf: "flex-start",
    padding: 0,
    margin: 0,
    minWidth: 0,
    textTransform: "none",
}));

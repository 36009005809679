import getUnixTime from "date-fns/getUnixTime";
import * as Yup from "yup";

import { FORM_ERROR, FORM_INPUT_LABELS } from "~constants/constants";

export function getStartAtAndEndAtValidation() {
    const currentDate = new Date();

    return {
        start_at: Yup.number()
            .max(getUnixTime(currentDate), "Start date can't be in the future")
            .label(FORM_INPUT_LABELS.START_DATE)
            .required(FORM_ERROR.REQUIRED),
        end_at: Yup.number()
            .min(Yup.ref("start_at"), "End date can't be before start date")
            .max(getUnixTime(currentDate), "End date can't be in the future")
            .nullable()
            .label(FORM_INPUT_LABELS.END_DATE),
    };
}

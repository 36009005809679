import { useTheme } from "@boomnation/lib-web-common";
import { Typography } from "@mui/material";
import React from "react";
import { Tooltip } from "react-tooltip";

import { LINK_TARGET, TOOLTIP_ID } from "~constants/constants";
import ExternalLink from "~shared/ExternalLink";

const DEFAULT_TOOLTIP_WIDTH = "350px";

export default function RootTooltip() {
    const theme = useTheme();
    const tooltipStyle = getTooltipStyles({ theme });

    return (
        <Tooltip
            style={tooltipStyle}
            id={TOOLTIP_ID}
            render={renderTooltip}
            clickable
        />
    );
}

function getTooltipStyles({ theme }) {
    return {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        boxShadow: theme.boxShadow.darker,
    };
}

// Exported for tests
export function renderTooltip({ content, activeAnchor }) {
    const sourceLink = activeAnchor?.getAttribute("source-link");
    const sourceLinkLabel = activeAnchor?.getAttribute("source-link-label");
    const width = activeAnchor?.getAttribute("tooltip-width");

    return (
        <Typography width={width || DEFAULT_TOOLTIP_WIDTH}>
            {content}
            {!!sourceLink && (
                <ExternalLink
                    label={sourceLinkLabel}
                    href={sourceLink}
                    target={LINK_TARGET.NEW_TAB}
                />
            )}
        </Typography>
    );
}

import PropTypes from "prop-types";
import React from "react";

import { useUploadAttachmentSafely } from "~app/hooks/cdnHooks";
import { useScreenSize } from "~app/hooks/utilHooks";
import { TEST_IDS } from "~constants/constants";
import { useUpdateUser } from "~graphql/hooks/users";
import {
    getInitialValues,
    handleSubmit,
} from "~shared/Form/editWorkFormHelpers";
import Form from "~shared/Form/Form";
import { getEditWorkFormSections } from "~shared/Form/formConfigs/editWorkFormConfigs";
import { getEditWorkValidationSchema } from "~shared/Form/profileValidationSchemas";

export default function EditWorkForm({
    user,
    title,
    submitButtonLabel,
    onSubmit,
}) {
    const { isSmScreen } = useScreenSize();
    const { updateUser, isLoading } = useUpdateUser({
        onCompleted: onSubmit,
    });

    const uploadAttachmentSafely = useUploadAttachmentSafely();

    return (
        <Form
            name={title}
            initialValues={getInitialValues(user)}
            sections={getEditWorkFormSections({ isSmScreen })}
            validationSchema={getEditWorkValidationSchema(user)}
            onSubmit={async (values) => {
                await handleSubmit({
                    values,
                    uploadAttachmentSafely,
                    updateUser,
                });
            }}
            id={TEST_IDS.FORM}
            isLoading={isLoading}
            isButtonCentered
            submitButtonLabel={submitButtonLabel}
            includeAsterisksOnRequiredFields
        />
    );
}

EditWorkForm.propTypes = {
    title: PropTypes.string,
    submitButtonLabel: PropTypes.string,
    user: PropTypes.shape({ bio: PropTypes.string }),
    onSubmit: PropTypes.func,
};

import { COPY } from "~constants/copyConstants";

export const FORM_INPUTS = {
    BUTTON: "form-button",
    PHONE: "phone",
    FIRST_NAME: "first-name",
    LAST_NAME: "last-name",
    EMAIL: "email",
    JOB_TITLE: "job-title",
    EMPLOYER: "employer",
    SCHOOL: "school",
    DIPLOMA: "diploma",
    LOCATION: "location",
    START_DATE: "start-date",
    END_DATE: "end-date",
    CURRENTLY_WORKING_HERE: "currently-working-here",
    IS_CURRENTLY_ATTENDING: "is-currently-attending",
    USER_TRADES: "user-trades",
    RESUME: "resume",
    CARD: "card",
    NAME: "name",
    DESCRIPTION: "description",
    CERTIFICATION_NAME: "certification-name",
    CERTIFICATION_NUMBER: "certification-number",
    CERTIFICATION_DATE: "certification-date",
    FILE_UPLOAD: "file-upload",
    BIO: "bio",
    TRADE: "trade",
    YEARS_OF_EXPERIENCE: "years-of-experience",
    VACCINE_NAME: "vaccine_name",
    MANUFACTURER_NAME: "manufacturer_name",
    FIRST_DOSE: "first_dose",
    SECOND_DOSE: "second_dose",
    LATEST_BOOSTER_DOSE: "latest_booster_dose",
    IMAGE: "image",
    RECRUITING: "recruiting",
    AVG_RECRUITING_EXPENSE: "avg_recruiting_expense",
    REFERRAL_BONUS: "referral_bonus",
    RETENTION: "retention",
    ONBOARDING_AND_TRAINING_HOURS: "onboarding_and_training_hours",
    AVG_HOURLY_WAGE: "avg_hourly_wage",
    ANNUAL_HOURS_PER_FTE: "annual_hours_per_fte",
    NUMBER_OF_FTES: "number_of_ftes",
    BURDEN_AND_BENEFITS_PERCENT: "burdens_and_benefits_percent",
    SG_A_PERCENT: "sg_a_percent",
    PROFIT_PERCENT: "profit_percent",
    SAVINGS_INFO: "info",
    SAVINGS_TOTAL: "total",
    FTE_CHURN_PERCENT: "fte_turn_percent",
    HIRES_FROM_REFERRALS_PERCENT: "hires_from_referrals_percent",
    REDUCTION_IN_CHURN_PERCENT: "reduction_in_churn_percent",
    DEFAULT_SUBSCRIPTION_COST: "default_subscription_cost",
    CUSTOM_SUBSCRIPTION_COST: "custom_subscription_cost",
};

// TODO form is not yet using useTranslation
export const FORM_ERROR = {
    PHONE: COPY.ERROR_FORM_PHONE.EN,
    REQUIRED: COPY.ERROR_FORM_REQUIRED.EN,
    EMAIL: COPY.ERROR_FORM_EMAIL.EN,
    BIO: COPY.ERROR_FORM_BIO.EN,
    FIRST_NAME: COPY.ERROR_FORM_FIRST_NAME.EN,
    LAST_NAME: COPY.ERROR_FORM_LAST_NAME.EN,
    LOCATION: COPY.ERROR_FORM_LOCATION.EN,
    IMAGE: COPY.ERROR_FORM_IMAGE.EN,
    FULL_ADDRESS: COPY.ERROR_FORM_FULL_ADDRESS.EN,
    GOOGLE_PLACES: COPY.ERROR_FORM_GOOGLE_PLACES.EN,
    NUMBER: COPY.ERROR_FORM_NUMBER.EN,
};

export const FORM_TYPES = {
    ADD: "Add",
    EDIT: "Edit",
};

export const FORM_NAMES = {
    EDUCATION: "Education",
    EXPERIENCE: "Experience",
    TRADE: "Trade",
    BIO: "Bio",
    PERSONAL_INFORMATION: "Personal Information",
    ADDITIONAL_QUESTIONS: COPY.ADDITIONAL_QUESTIONS.EN,
    WORKER_DETAILS: "Worker Details",
    USER_INFO: "USER_INFO",
};

export const BUTTON_LABELS = {
    SAVE: "Save",
    CONTINUE: COPY.CONTINUE.EN,
    APPLY: "Apply",
    CANCEL: "Cancel",
};

export const GENERIC_LABEL = "Input";

export const FORM_SUBMIT_BUTTON_LABELS = {
    DEFAULT: BUTTON_LABELS.SAVE,
    ADDITIONAL_QUESTIONS: BUTTON_LABELS.CONTINUE,
    PERSONAL_INFORMATION: BUTTON_LABELS.CONTINUE,
    WORKER_DETAILS: BUTTON_LABELS.APPLY,
};

export const FORM_CANCEL_BUTTON_LABELS = {
    DEFAULT: BUTTON_LABELS.CANCEL,
};

export const FORM_INPUT_LABELS = {
    BIO: COPY.INPUT_LABELS_BIO.EN,
    FIRST_NAME: COPY.INPUT_LABELS_FIRST_NAME.EN,
    LAST_NAME: COPY.INPUT_LABELS_LAST_NAME.EN,
    EMAIL: COPY.INPUT_LABELS_EMAIL.EN,
    SCHOOL: COPY.INPUT_LABELS_SCHOOL.EN,
    DIPLOMA: COPY.INPUT_LABELS_DIPLOMA.EN,
    LOCATION: COPY.INPUT_LABELS_LOCATION.EN,
    START_DATE: COPY.INPUT_LABELS_START_DATE.EN,
    END_DATE: COPY.INPUT_LABELS_END_DATE.EN,
    CURRENTLY_ATTENDING_SCHOOL: COPY.INPUT_LABELS_CURRENTLY_ATTENDING_SCHOOL.EN,
    JOB_TITLE: COPY.INPUT_LABELS_JOB_TITLE.EN,
    EMPLOYER: COPY.INPUT_LABELS_EMPLOYER.EN,
    CURRENTLY_WORKING_IN_ROLE: COPY.INPUT_LABELS_CURRENTLY_WORKING_IN_ROLE.EN,
    ANSWER: COPY.INPUT_LABELS_ANSWER.EN,
    SEARCH_TRADE: COPY.INPUT_LABELS_SEARCH_TRADE.EN,
    YEARS_OF_EXPERIENCE: COPY.INPUT_LABELS_YEARS_OF_EXPERIENCE.EN,
    SET_AS_PRIMARY_TRADE: COPY.INPUT_LABELS_SET_AS_PRIMARY_TRADE.EN,
    UPLOAD_RESUME: COPY.INPUT_LABELS_UPLOAD_RESUME.EN,
    CERTIFICATION: COPY.INPUT_LABELS_CERTIFICATION.EN,
    CERTIFICATION_NAME: COPY.INPUT_LABELS_CERTIFICATION_NAME.EN,
    CERTIFICATION_NUMBER: COPY.INPUT_LABELS_CERTIFICATION_NUMBER.EN,
    CERTIFICATION_DATE: COPY.INPUT_LABELS_CERTIFICATION_DATE.EN,
    VACCINATION_NAME: COPY.INPUT_LABELS_VACCINATION_NAME.EN,
    VACCINATION_MANUFACTURER: COPY.INPUT_LABELS_VACCINATION_MANUFACTURER.EN,
    FIRST_DOSE_DATE: COPY.INPUT_LABELS_FIRST_DOSE_DATE.EN,
    SECOND_DOSE_DATE: COPY.INPUT_LABELS_SECOND_DOSE_DATE.EN,
    LATEST_BOOSTER_DATE: COPY.INPUT_LABELS_LATEST_BOOSTER_DATE.EN,
    PHONE_NUMBER: COPY.INPUT_LABELS_PHONE_NUMBER.EN,
    TRADE: COPY.INPUT_LABELS_TRADE.EN,
    INDUSTRY: COPY.INPUT_LABELS_INDUSTRY.EN,
    AVAILABLE_FOR_WORK: COPY.INPUT_LABELS_AVAILABLE_FOR_WORK.EN,
    PROFILE_IMAGE: COPY.INPUT_LABELS_PROFILE_IMAGE.EN,
    AVG_RECRUITING_EXPENSE: COPY.AVG_RECRUITING_EXPENSE.EN,
    REFERRAL_BONUS: COPY.REFERRAL_BONUS.EN,
    RETENTION: COPY.RETENTION.EN,
    ONBOARDING_AND_TRAINING_HOURS: COPY.ONBOARDING_AND_TRAINING_HOURS.EN,
    AVG_HOURLY_WAGE: COPY.AVG_HOURLY_WAGE.EN,
    ANNUAL_HOURS_PER_FTE: COPY.ANNUAL_HOURS_PER_FTE.EN,
    NUMBER_OF_FTES: COPY.NUMBER_OF_FTES.EN,
    BURDEN_AND_BENEFITS_PERCENT: COPY.BURDEN_AND_BENEFITS_PERCENT.EN,
    SG_A_PERCENT: COPY.SG_A_PERCENT.EN,
    PROFIT_PERCENT: COPY.PROFIT_PERCENT.EN,
    DEFAULT_SUBSCRIPTION_COST: COPY.DEFAULT_SUBSCRIPTION_COST.EN,
    CUSTOM_SUBSCRIPTION_COST: COPY.CUSTOM_SUBSCRIPTION_COST.EN,
    FTE_CHURN_PERCENT: COPY.FTE_CHURN_PERCENT.EN,
    HIRES_FROM_REFERRALS_PERCENT: COPY.HIRES_FROM_REFERRALS_PERCENT.EN,
    REDUCTION_IN_CHURN_PERCENT: COPY.REDUCTION_IN_CHURN_PERCENT.EN,
};

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Route } from "react-router-dom";

import analytics from "~services/analytics";

export default function RouteWithPageAnalytics(props) {
    const { computedMatch } = props;

    useEffect(() => {
        if (!computedMatch) return;

        const url = `${window.location.origin}${computedMatch.url}`;

        analytics.sendPageEvent({ path: computedMatch.path, url });
    }, []);

    return <Route {...props} />;
}

RouteWithPageAnalytics.propTypes = {
    computedMatch: PropTypes.shape({
        url: PropTypes.string,
        path: PropTypes.string,
    }),
};

import React, { useState, useEffect, useMemo } from "react";

import { COPY } from "~constants/copyConstants";
import { useCurrentUser } from "~graphql/hooks/users";
import { isSmsUser } from "~services/authHelpers";
import EditWorkForm from "~shared/Form/EditWorkForm";
import PromptForProfileInfoModalContainer from "~shared/Modal/PromptForProfileInfoModalContainer";

export default function PromptForProfileInfoModal() {
    const { user } = useCurrentUser();
    const { isPromptOpen, hidePrompt } = useShouldShowPrompt({ user });
    const { title, subtitle, submitButtonLabel } = getCopy({ user });

    return (
        <PromptForProfileInfoModalContainer
            title={title}
            subtitle={subtitle}
            isOpen={isPromptOpen}
        >
            <EditWorkForm
                user={user}
                onSubmit={() => hidePrompt(false)}
                submitButtonLabel={submitButtonLabel}
            />
        </PromptForProfileInfoModalContainer>
    );
}

function useShouldShowPrompt({ user }) {
    const shouldPrompt = useMemo(() => getShouldPrompt(user), [user]);
    const [isOpen, setIsOpen] = useState(shouldPrompt);

    useEffect(() => {
        setIsOpen(shouldPrompt);
    }, [shouldPrompt]);

    return { isPromptOpen: isOpen, hidePrompt: setIsOpen };
}

function getShouldPrompt(user) {
    const isMissingName = !user?.first_name || !user?.last_name;
    const isMissingRequiredInfo = getIsMissingRequiredInfo({
        user,
        isMissingName,
    });
    const shouldPrompt = !!user && isMissingRequiredInfo;

    return shouldPrompt;
}

function getIsMissingRequiredInfo({ user, isMissingName }) {
    const isMissingDefaultRequiredInfo = isMissingName || !user?.phone_number;

    return isSmsUser(user)
        ? isMissingDefaultRequiredInfo
        : isMissingDefaultRequiredInfo || !user?.email;
}

function getCopy({ user }) {
    const companyName = user?.companies?.[0]?.name;
    const isCompanyWorker = !!companyName;

    return {
        title: getTitle(companyName),
        subtitle: getSubtitle(isCompanyWorker),
        submitButtonLabel: getSubmitButtonLabel(isCompanyWorker),
    };
}

function getTitle(companyName) {
    return companyName
        ? `${COPY.PROMPT_USER_INFO_WORKER_TITLE.EN} ${companyName}`
        : COPY.PROMPT_USER_INFO_JOB_SEEKER_TITLE.EN;
}

function getSubtitle(isCompanyWorker) {
    return isCompanyWorker
        ? COPY.PROMPT_USER_INFO_WORKER_SUBTITLE.EN
        : COPY.PROMPT_USER_INFO_JOB_SEEKER_SUBTITLE.EN;
}

function getSubmitButtonLabel(isCompanyWorker) {
    return isCompanyWorker
        ? COPY.PROMPT_USER_INFO_SUBMIT_BUTTON_WORKER.EN
        : COPY.PROMPT_USER_INFO_SUBMIT_BUTTON_JOB_SEEKER.EN;
}

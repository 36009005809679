import { useHistory } from "react-router-dom";

import { ROUTES } from "~constants/constants";

export function useNavigationHelpers() {
    const history = useHistory();

    return {
        goToLanding: createWrappedGoTo(history, goToLanding),
        goToJob: createWrappedGoTo(history, goToJob),
        goToJobs: createWrappedGoTo(history, goToJobs),
        goToApplicationSuccess: createWrappedGoTo(
            history,
            goToApplicationSuccess
        ),
        goToCompany: createWrappedGoTo(history, goToCompany),
        goToCollection: createWrappedGoTo(history, goToCollection),
        goToAppStore: createWrappedGoTo(history, goToAppStore),
        goToUserProfile: createWrappedGoTo(history, goToUserProfile),
        goToOwnProfile: createWrappedGoTo(history, goToOwnProfile),
        goToProject: createWrappedGoTo(history, goToProject),
        goToMessages: createWrappedGoTo(history, goToMessages),
        goToCompanyProjects: createWrappedGoTo(history, goToCompanyProjects),
        goToWhyBoomnationTab: createWrappedGoTo(history, goToWhyBoomnationTab),
        goToCompanyTab: createWrappedGoTo(history, goToCompanyTab),
    };
}

function createWrappedGoTo(history, goTo) {
    return (params) => goTo({ ...params, history });
}

function goToLanding({ history }) {
    history.push(ROUTES.LANDING);
}

function goToJob({ jobId, searchString = "", history }) {
    history.push({
        pathname: `/jobs/${jobId}`,
        search: searchString,
    });
}

function goToJobs({ searchString = "", history }) {
    history.push({ pathname: ROUTES.JOBS, search: searchString });
}

function goToCompany({ history, companyId }) {
    history.push(`/companies/${companyId}`);
}

function goToCollection({ history, collectionId }) {
    history.push(`/collections/${collectionId}`);
}

function goToApplicationSuccess({ jobId, history }) {
    history.push({ pathname: ROUTES.APPLICATION_SUCCESS, state: { jobId } });
}

function goToUserProfile({ userId, history }) {
    history.push(`/users/${userId}`);
}

function goToOwnProfile({ history }) {
    history.push(`/profile`);
}

function goToProject({ history, projectId, searchString = "" }) {
    history.push({ pathname: `/projects/${projectId}`, search: searchString });
}

function goToCompanyProjects({ history, companyId, searchString = "" }) {
    history.push({
        pathname: `/companies/${companyId}/projects`,
        search: searchString,
    });
}

function goToMessages({ history }) {
    history.push(`/messages`);
}

function goToWhyBoomnationTab({ history, tab }) {
    history.push(`${ROUTES.WHY_BOOMNATION}?tab=${tab}`);
}

function goToCompanyTab({ history, tab }) {
    history.push(`${ROUTES.COMPANY}?tab=${tab}`);
}

function goToAppStore() {
    // Redirect to external url

    window.location.href = process.env.UNIVERSAL_STORE_URL;
}

export function getCurrentUserProfileRoute({ userId }) {
    return `/users/${userId}`;
}

export function getWorkerCompanyRoute({ companyId }) {
    return `/companies/${companyId}/projects`;
}

export function getWorkerProjectRoute({ projectId }) {
    return `/projects/${projectId}`;
}

import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import analytics from "~services/analytics";

function ExternalLink({
    className,
    href,
    target,
    label,
    analyticsEventName,
    children,
    onClick,
}) {
    const ref = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if (!ref?.current) return;

        analytics.trackExternalLink({
            anchorElement: ref.current,
            eventName: analyticsEventName,
        });
    }, [ref]);

    return (
        <Link
            ref={ref}
            href={href}
            target={target}
            underline="hover"
            className={className}
            onClick={onClick}
        >
            {children || t(label)}
        </Link>
    );
}

ExternalLink.propTypes = {
    children: PropTypes.any,
    href: PropTypes.string,
    target: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    analyticsEventName: PropTypes.string,
    onClick: PropTypes.func,
};

export default ExternalLink;

import {
    ENTITY_LINK_TYPES,
    ENTITY_QUERY_STRING_PARAMETER_ABBREVIATIONS,
} from "@boomnation/lib-web-common";
import { useEffect } from "react";

import { useLocationQuery } from "~app/hooks/utilHooks";
import { useCurrentUser } from "~graphql/hooks/users";
import analytics from "~services/analytics";

export function useUserAnalytics() {
    const { user } = useCurrentUser();

    useEffect(() => {
        if (!user) return;

        analytics.sendIdentifyEvent(user);
    }, [user?.id]);
}

export function useJobViewedAnalytics(job) {
    const { shared } = useLocationQuery();

    useEffect(() => {
        if (!job) return;

        analytics.sendJobViewedEvent(job);

        if (!shared) return;

        analytics.sendSharedJobViewedEvent(job);
    }, [job]);
}

export function useCompanyViewedAnalytics(company) {
    useEffect(() => {
        if (!company) return;

        analytics.sendCompanyViewedEvent(company);
    }, [company]);
}

export function useProjectLinkFollowedEvent({ project }) {
    const searchParams = useLocationQuery();
    const linkType = getProjectLinkType(searchParams);

    useEffect(() => {
        if (!project || !linkType) return;

        analytics.sendProjectLinkFollowedEvent({ project, linkType });
    }, [project, linkType]);
}

function getProjectLinkType(searchParams) {
    const linkType =
        searchParams[ENTITY_QUERY_STRING_PARAMETER_ABBREVIATIONS.TYPE_KEY];

    if (linkType) {
        switch (linkType) {
            case ENTITY_QUERY_STRING_PARAMETER_ABBREVIATIONS.TYPE_VALUE_QR:
                return ENTITY_LINK_TYPES.QR_CODE;
            case ENTITY_QUERY_STRING_PARAMETER_ABBREVIATIONS.TYPE_VALUE_LINK:
                return ENTITY_LINK_TYPES.LINK;
            default:
                return ENTITY_LINK_TYPES.LINK;
        }
    }

    if (searchParams.code) return ENTITY_LINK_TYPES.INVITATION;

    return null;
}

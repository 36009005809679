import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import React from "react";

import ErrorMessage from "~shared/Error/ErrorMessage";
import PageSectionHeader from "~shared/Page/PageSectionHeader";
import Skeleton from "~shared/Skeleton";

export default function PageSection({
    refetch,
    isLoading,
    error,
    title,
    headerContent,
    noPadding,
    noMarginTop,
    noMarginBottom,
    hideHeaderBorder,
    isBackgroundVisible,
    width,
    children,
}) {
    if (isLoading) {
        return (
            <Skeleton
                variant="rectangular"
                width="100%"
                height={250}
                isRounded
            />
        );
    }

    return (
        <SectionContainer
            width={width}
            isBackgroundVisible={isBackgroundVisible}
            noMarginTop={noMarginTop}
        >
            {error ? (
                <ErrorMessage error={error} onButtonClick={refetch} />
            ) : (
                <>
                    <PageSectionHeader
                        title={title}
                        headerContent={headerContent}
                        hideHeaderBorder={hideHeaderBorder}
                    />
                    <SectionContent
                        noPadding={noPadding}
                        noMarginBottom={noMarginBottom}
                    >
                        {children}
                    </SectionContent>
                </>
            )}
        </SectionContainer>
    );
}

PageSection.propTypes = {
    error: PropTypes.object,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    headerContent: PropTypes.node,
    children: PropTypes.node,
    refetch: PropTypes.func,
    noPadding: PropTypes.bool,
    noMarginTop: PropTypes.bool,
    noMarginBottom: PropTypes.bool,
    hideHeaderBorder: PropTypes.bool,
    isBackgroundVisible: PropTypes.bool,
    width: PropTypes.string,
};

const SectionContainer = styled(Paper, {
    shouldForwardProp: (prop) =>
        prop !== "isBackgroundVisible" && prop !== "noMarginTop",
})(({ theme, width, isBackgroundVisible, noMarginTop }) => ({
    width: width || "100%",
    marginTop: noMarginTop ? "0px" : "20px",
    minHeight: 100,
    backgroundImage: "none",
    backgroundColor: isBackgroundVisible
        ? theme.palette.card.background
        : "transparent",
    borderRadius: theme.borderRadius.lg,
    boxShadow: isBackgroundVisible ? theme.boxShadow : "none",
}));

const SectionContent = styled(Box, {
    shouldForwardProp: (prop) =>
        prop !== "noPadding" && prop !== "noMarginBottom",
})(({ theme, noPadding, noMarginBottom }) => ({
    padding: noPadding ? 0 : theme.spacing(2),
    marginBottom: !noMarginBottom && 20,
    overflow: "auto",
}));

/* eslint-disable max-lines */
export * from "~constants/formConstants";

export const TEST_IDS = {
    APP: "APP",
    AVATAR: "AVATAR",
    LOGIN: "LOGIN",
    LOGO: "LOGO",
    NAV_BAR_MENU: "NAV_BAR_MENU",
    ADD_BUTTON: "ADD_BUTTON",
    REMOVE_BUTTON: "REMOVE_BUTTON",
    GO_BACK_BUTTON: "BACK_BUTTON",
    CLOSE_BUTTON: "CLOSE_BUTTON",
    SUBTITLE: "SUBTITLE",
    SKELETON: "SKELETON",
    MODAL: "MODAL",
    MASONRY: "MASONRY",
    GRID_SECTIONS: "GRID_SECTIONS",
    ERROR_WITH_RETRY: "ERROR_WITH_RETRY",
    DROPDOWN_INPUT: "DROPDOWN_INPUT",
    PAGE_ERROR: "PAGE_ERROR",
    ATTACHMENT_PREVIEW: "ATTACHMENT_PREVIEW",
    JOB_DETAILS_SKELETON: "JOB_DETAILS_SKELETON",
    JOB_CARD: "JOB_CARD",
    APP_STORE_LOGO: "APP_STORE_LOGO",
    PLAY_STORE_LOGO: "PLAY_STORE_LOGO",
    SNACKBAR_CLOSE_BUTTON: "SNACKBAR_CLOSE_BUTTON",
    COLLECTION: "COLLECTION",
    COLLECTION_BANNER: "COLLECTION_BANNER",
    COLLECTION_SKELETON: "COLLECTION_SKELETON",
    TOP_JOBS_COLLECTION: "TOP_JOBS_COLLECTION",
    TOP_JOBS_COLLECTION_SKELETON: "TOP_JOBS_COLLECTION_SKELETON",
    RECOMMENDED_JOBS_SKELETON: "RECOMMENDED_JOBS_SKELETON",
    ACTIONABLE_BUTTON: "ACTIONABLE_BUTTON",
    PROFILE_SKELETON: "PROFILE_SKELETON",
    USER_PROFILE_BADGE: "USER_PROFILE_BADGE",
    USER_PROFILE_CHECKBOX: "USER_PROFILE_CHECKBOX",
    FORM: "FORM",
    COMPANY_AVATAR: "COMPANY_AVATAR",
    JOB_SEARCH: "JOB_SEARCH",
    JOB_SEARCH_OPTIONS: "JOB_SEARCH_OPTIONS",
    SELECT: "SELECT",
    LIST_COLUMN_SKELETON: "LIST_COLUMN_SKELETON",
    TRANSLATE_BUTTON: "TRANSLATE_BUTTON",
    SORT_BUTTON: "SORT_BUTTON",
    LANDING_PAGE: "LANDING_PAGE",
    WHY_BOOMNATION_PAGE: "WHY_BOOMNATION_PAGE",
    COMPANY_PAGE: "COMPANY_PAGE",
    LANDING_LAYOUT_PAGE: "LANDING_LAYOUT_PAGE",
    SEARCH_BUTTON: "SEARCH_BUTTON",
    OPEN_MENU_BUTTON: "OPEN_MENU_BUTTON",
    FILTER_BUTTON: "FILTER_BUTTON",
    FILTER_MODAL_SUBMIT_BUTTON: "FILTER_MODAL_SUBMIT_BUTTON",
    PROFILE_TAB: "PROFILE_TAB",
    JOBS_TAB: "JOBS_TAB",
    COMPANY_FOR_WORKER_DESKTOP: "COMPANY_FOR_WORKER_DESKTOP",
    COMPANY_FOR_WORKER_MOBILE: "COMPANY_FOR_WORKER_MOBILE",
    PROJECT_DETAILS: "PROJECT_DETAILS",
    CONTENT_TAB: "CONTENT_TAB",
    BULLETIN_TAB: "BULLETIN_TAB",
    FOOTER_SKELETON: "FOOTER_SKELETON",
    CONFIRMATION_MODAL_BUTTON: "CONFIRMATION_MODAL_BUTTON",
    STEP_BY_STEP_HEADER: "STEP_BY_STEP_HEADER",
    PROJECT_DETAILS_BACK_BUTTON: "PROJECT_DETAILS_BACK_BUTTON",
    SIDEBAR_COLLAPSE_BUTTON: "SIDEBAR_COLLAPSE_BUTTON",
    SIDEBAR_EXPAND_BUTTON: "SIDEBAR_EXPAND_BUTTON",
    COMPANIES_AND_PROJECTS_COLUMN: "COMPANIES_AND_PROJECTS_COLUMN",
    REFERRAL_BANNER_COLLAPSE_BUTTON: "REFERRAL_BANNER_COLLAPSE_BUTTON",
    SHARE_BUTTON: "SHARE_BUTTON",
    SEARCH: "SEARCH",
    REFERRAL_BANNER_COLLAPSE_CONTAINER: "REFERRAL_BANNER_COLLAPSE_CONTAINER",
    SAVINGS_CALCULATOR_PAGE: "SAVINGS_CALCULATOR_PAGE",
    CAMPAIGN_FORM_PAGE: "CAMPAIGN_FORM_PAGE",
    AVG_RECRUITING_EXPENSE: "AVG_RECRUITING_EXPENSE",
    REFERRAL_BONUS: "REFERRAL_BONUS",
    ONBOARDING_AND_TRAINING_HOURS: "ONBOARDING_AND_TRAINING_HOURS",
    AVG_HOURLY_WAGE: "AVG_HOURLY_WAGE",
    ANNUAL_HOURS_PER_FTE: "ANNUAL_HOURS_PER_FTE",
    NUMBER_OF_FTES: "NUMBER_OF_FTES",
    BURDEN_AND_BENEFITS_PERCENT: "BURDEN_AND_BENEFITS_PERCENT",
    SG_A_PERCENT: "SG_A_PERCENT",
    PROFIT_PERCENT: "PROFIT_PERCENT",
    DEFAULT_SUBSCRIPTION_COST: "DEFAULT_SUBSCRIPTION_COST",
    CUSTOM_SUBSCRIPTION_COST: "CUSTOM_SUBSCRIPTION_COST",
    SUBSCRIPTION_COST_DISPLAY: "SUBSCRIPTION_COST_DISPLAY",
    FTE_CHURN: "FTE_CHURN",
    FTE_CHURN_PERCENT: "FTE_CHURN_PERCENT",
    ONBOARDING_TRAINING_TESTING: "ONBOARDING_TRAINING_TESTING",
    LOST_PROFIT: "LOST_PROFIT",
    ANNUAL_COST_IMPACT: "ANNUAL_COST_IMPACT",
    HIRES_FROM_REFERRALS_PERCENT: "HIRES_FROM_REFERRALS_PERCENT",
    SAVINGS_PER_REFERRAL_HIRE: "SAVINGS_PER_REFERRAL_HIRE",
    ANNUAL_RECRUITING_SAVINGS: "ANNUAL_RECRUITING_SAVINGS",
    ANNUAL_RECRUITING_SAVINGS_PERCENT: "ANNUAL_RECRUITING_SAVINGS_PERCENT",
    ANNUAL_RETENTION_SAVINGS: "ANNUAL_RETENTION_SAVINGS",
    ANNUAL_RETENTION_SAVINGS_PERCENT: "ANNUAL_RETENTION_SAVINGS_PERCENT",
    COST_TO_HIRE_CHURNED_FTES: "COST_TO_HIRE_CHURNED_FTES",
    REDUCTION_IN_CHURN_PERCENT: "REDUCTION_IN_CHURN_PERCENT",
    REDUCTION_IN_CHURN_PERCENT_DISPLAY: "REDUCTION_IN_CHURN_PERCENT_DISPLAY",
    RECRUITING_AND_RETENTION_SAVINGS: "RECRUITING_AND_RETENTION_SAVINGS",
    TOTAL_ANNUAL_SAVINGS_DISPLAY: "TOTAL_ANNUAL_SAVINGS_DISPLAY",
    TOTAL_ANNUAL_SAVINGS_PERCENT_DISPLAY:
        "TOTAL_ANNUAL_SAVINGS_PERCENT_DISPLAY",
    RECRUITING_TAB: "RECRUITING_TAB",
    COMMUNITY_TAB: "COMMUNITY_TAB",
    COMMUNICATION_TAB: "COMMUNICATION_TAB",
    FULL_IMAGE: "FULL_IMAGE",
    IMAGES_IN_PARTS: "IMAGES_IN_PARTS",
    SCROLL_BUTTON: "SCROLL_BUTTON",
    PROMOTION_BANNER: "PROMOTION_BANNER",
    LINK: "LINK",
};

export const THEME_MODE = {
    LIGHT: "light",
    DARK: "dark",
};

export const TABLES = {
    FIRST_CELL: "FIRST_CELL",
};

export const PAGES = {
    LANDING: "landing",
    WHY_BOOMNATION: "why-boomnation",
    COMPANY: "company",
    COLLECTION: "collection",
    JOB_DETAILS: "job-details",
    JOBS: "jobs",
    APPLICATION_SUCCESS: "application-success",
    COMPANY_FOR_JOB_SEEKER: "company-for-job-seeker",
    DEEP_LINK_HUB: "deep-link-hub",
    DEFAULT: "default",
    PROFILE: "profile",
    USER_PROFILE: "user-profile",
    PROJECT_DETAILS: "project-details",
    COMPANY_PROJECTS: "company-projects",
    MESSAGES: "messages",
    NEWSFEED: "newsfeed",
    SIMPLE_PAGES: "simple-pages",
    COMPANY_PROJECTS_WELCOME: "compay-projects-welcome",
    PROJECT_DETAILS_WELCOME: "project-details-welcome",
    PROJECT_WORKER_REFERRALS: "worker-referrals",
    SAVINGS_CALCULATOR: "savings-calculator",
    CAMPAIGN_FORM: "campaign-form",
};

export const ROUTES = {
    LANDING: "/",
    WHY_BOOMNATION: "/why-boomnation",
    COMPANY: "/company",
    COLLECTION: "/collections/:id",
    JOB_DETAILS: "/jobs/:id",
    JOBS: "/jobs",
    APPLICATION_SUCCESS: "/application-success",
    PROFILE: "/profile",
    USER_PROFILE: "/users/:id",
    COMPANY_FOR_JOB_SEEKER: "/companies/:id",
    DEEP_LINK_HUB: "/dl",
    PROJECT_DETAILS: "/projects/:id",
    COMPANY_PROJECTS: "/companies/:id/projects",
    MESSAGES: "/messages",
    NEWSFEED: "/newsfeed",
    SIMPLE_PAGES: "/pages/:id",
    PROJECTS: "/projects",
    COMPANY_PROJECTS_WELCOME: "/companies/:id/projects/welcome",
    PROJECT_DETAILS_WELCOME: "/projects/:id/welcome",
    PROJECT_WORKER_REFERRALS: "/projects/:id/referrals",
    SAVINGS_CALCULATOR: "/savings",
    CAMPAIGN_FORM: "/campaigns/:cid/form/:fid",
};

export const SORT_ORDERS = {
    DESCENDING: "desc",
    ASCENDING: "asc",
};

export const DEFAULT_LIMITS = {
    PLACES_OF_EMPLOYMENT: 5,
    SCHOOLS: 5,
    SIMILAR_JOBS: 8,
    RECOMMENDED_JOBS: 6,
    APPLIED_FOR_JOBS: 10,
    PUBLIC_COMPANY_JOBS: 6,
    SUGGESTIONS: 5,
    JOBS: 10,
    WORKER_PROJECTS: 10,
};

export const WORKER_ROUTE_IDENTIFIER = {
    PROJECTS: "/projects",
};

// Unix timestamp is invalid after 2050-01-01
export const MAX_DATE = new Date("2050-01-01");

export const DEFAULT_SEARCH_BY_NAME_SORT = {
    by: "name.keyword",
    order: SORT_ORDERS.ASCENDING,
};

export const DEFAULT_WORKER_PROJECTS_SORT = {
    by: "name",
    order: SORT_ORDERS.ASCENDING,
};

export const JOBS_SORT_BY = {
    CREATED_AT: "created_at",
    PER_DIEM: "per_diem_range_max",
    HIGHEST_PAY: "payment_range_max",
};

export const DEFAULT_WORKER_ASSIGNED_LIMIT = 15;

export const DEFAULT_DEBOUNCE_TIMEOUT = 300;

export const DEBOUNCE_KEYS = {
    GET_SUGGESTIONS: "getSuggestions",
    GET_TRADES: "getTrades",
    SEARCH_PLACES_OF_EMPLOYMENT: "searchPlacesOfEmployment",
};

export const PAY_TYPE = {
    FIXED: "fixed",
    RANGE: "range",
};

export const JOB_PAY_PROP_NAMES = {
    PAYMENT: "payment",
    PER_DIEM: "per_diem",
};

export const AVATAR_VARIANTS = {
    SQUARE: "square",
    CIRCULAR: "circular",
    ROUNDED: "rounded",
};

export const IMAGE_VARIANTS = {
    THUMBNAIL: "thumbnail",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
};

export const BUTTON_VARIANTS = {
    OUTLINED: "outlined",
    CONTAINED: "contained",
    TEXT: "text",
};

export const CERTIFICATION_STATUSES = {
    PENDING: "pending",
    VERIFIED: "verified",
};

export const QUERY_REFETCH_DELAY = 1000;

export const CDN_ACCESS_TYPES = {
    READ: "read",
    DELETE: "delete",
};

export const CDN_GRANTED_ENTITY_TYPES = {
    USER: "user",
    COMPANY: "company",
    ANY_COMPANY: "any_company",
    ROLE: "role",
    EVERYONE: "everyone",
};

export const ANALYTICS_EVENTS = {
    JOB_APPLICATION_STARTED: "job_application_started",
    JOB_APPLICATION_COMPLETED: "job_application_completed",
    JOB_VIEWED: "job_viewed",
    COMPANY_VIEWED: "company_viewed",
    MOBILE_APP_LINK_CLICKED: "mobile_app_link_clicked",
    EXTERNAL_LINK_CLICKED: "external_link_clicked",
    ANNOUNCEMENT_LINK_CLICKED: "announcement_link_clicked",
    JOB_SEARCH_PERFORMED: "job_search_performed",
    NO_JOBS_FOUND: "no_jobs_found",
    SHARED_JOB_VIEWED: "shared_job_viewed",
    ACCEPTED_COMPANY_INVITATION: "accepted_company_invitation",
    SCROLL_TO_BULLETIN_POST_SUCCESS: "scroll_to_bulletin_post_success",
    SCROLL_TO_BULLETIN_POST_FAILURE: "scroll_to_bulletin_post_failure",
    SHARE_CLICKED: "share_clicked",
    PROJECT_LINK_FOLLOWED: "project_link_followed",
    REFERRAL_SUBMITTED: "referral_submitted",
    APPLY_ON_EXTERNAL_LINK_PRESSED: "apply_on_external_link_pressed",
    SAVINGS_CALCULATED: "savings_calculated",
    GO_TO_MY_PROJECT_LANDING_BUTTON_CLICKED:
        "go_to_my_project_landing_button_clicked",
};

// Collection ids may differ between dev/prod, so they are
// being stored in env vars.
export const COLLECTION_IDS = {
    TOP_JOBS: process.env.TOP_JOBS_COLLECTION_ID,
};

export const COLLECTION_ENTITY_TYPE = {
    JOB: "job",
};

export const JOB_SOURCE = {
    NEWSFEED: "Newsfeed",
    JOBS: "Jobs",
};

export const STATUSES = {
    PENDING: "pending",
    VERIFIED: "verified",
};

export const FORM_INPUT_TYPES = {
    STRING: "string",
    NUMBER: "number",
    DATE: "date",
    API_ENUM: "api_enum",
    MULTISELECT_ENUM: "multiselect_enum",
    ENUM: "enum",
    ATTACHMENT_URL: "attachment_url",
};

export const DEEP_LINK_TYPES = {
    REDIRECT: "redirect",
};

export const FORM_INPUT_VALUE_TYPES = {
    STRING: "string_value",
    DATE: "timestamp_value",
    ENTITY: "entity_value",
    ID_AND_NAME: "id_name_value",
    ID_AND_NAME_VALUES: "id_name_values",
};

export const HEIGHT = {
    FORM_INPUT: 48,
};

export const ARIA_LABELS = {
    ADDRESS_ERROR: "address",
};

export const EXTERNAL_LINKS = {
    HIRING_WEB_URL: process.env.HIRING_WEB_URL,
    EMPLOYERS: "https://partners.boomnation.com",
    INSTAGRAM: "https://www.instagram.com/boomnationusa/",
    // eslint-disable-next-line no-secrets/no-secrets
    YOUTUBE: "https://www.youtube.com/channel/UCQXvdb54bC7JhqkuZPPNCag",
    FACEBOOK: "https://www.facebook.com/BoomNationJobs/",
    USER_AGREEMENT: "https://boomnation.com/pages/terms-and-conditions",
    JOB_POSTING_AGREEMENT: "https://boomnation.com/pages/job-posting-agreement",
    USER_PRIVACY_NOTICE: "https://boomnation.com/pages/privacy-policy",
};

export const DEEP_LINK_ENTITY_TYPES = {
    JOB: "job",
    JOBS: "jobs",
    CHAT_CHANNEL: "chat_channel",
    POST: "post",
    USER: "user",
    EDIT_PROFILE: "edit_profile",
    NOTIFICATIONS: "notifications",
    NOTIFICATIONS_PREFERENCES: "notifications_preferences",
    COLLECTION: "collection",
    COMPANY: "company",
};

export const LANGUAGES = {
    EN: "en",
    ES: "es",
};

export const IS_APPLYING_SEARCH_PARAM = "is_applying=true";

export const LANDING_SECTION_TYPE = {
    ANNOUNCEMENT: "worker_web_home_screen_announcement",
    HEADER: "worker_web_home_screen_header",
    HEADER_GO_TO_PROJECT: "worker_web_home_screen_go_to_project_header",
    TESTIMONIALS: "worker_web_home_screen_testimonials",
    FEATURES: "worker_web_home_screen_features",
    TOP_JOBS: "worker_web_home_screen_top_jobs",
    FEATURED_IN: "worker_web_home_screen_featured_in",
    INDUSTRIES: "worker_web_home_screen_industries",
};

export const SUGGESTION_ENTITY_TYPES = {
    JOB: "job",
};

export const JOBS_PER_ROW = {
    XS_SCREEN: 1,
    SM_SCREEN: 2,
    MD_SCREEN: 3,
};

export const DEFAULT_SEARCH_RADIUS = 50;

export const KEYBOARD_KEY_CODES = {
    ENTER: "Enter",
};

export const MUI_SIZE = {
    SMALL: "small",
};

export const MUI_POSITION = {
    START: "start",
    END: "end",
};

export const LINK_TARGET = {
    NEW_TAB: "_blank",
};

export const LOCAL_STORAGE_KEYS = {
    LOCATION: "location",
    FORWARDING: "forwarding",
};

export const COMPANY_ROLES = {
    WORKER: "worker",
    OWNER: "owner",
};

export const COMPANY_FEATURES = {
    PROJECT_REFERRALS: "project_referrals",
};

export const ENTITY_TYPES = {
    COMPANIES: "companies",
    PROJECTS: "projects",
    BULLETIN_POSTS: "bulletin_posts",
};

export const SORT = {
    DESC: "desc",
    ASC: "asc",
};

export const DEFAULT_SORT = {
    by: "created_at",
    order: SORT.DESC,
};

export const BULLETIN_BOARD_HEIGHT = "50rem";

export const CONTENT_BLOCK_TYPES = {
    FORM: "FORM",
    BANNER: "BANNER",
};

export const FORM_SUCCESS_MESSAGES = {
    FORM_SUBMISSION: "Form successfully submitted!",
    DELETE_FORM_SUBMISSION: "Submission successfully deleted!",
    COMPANY_LEFT_SUCCESS: "Company left!",
};

export const FORM_FIELD_TYPES = {
    SHORT_ANSWER: "SHORT_ANSWER",
    PARAGRAPH: "PARAGRAPH",
    DROPDOWN: "DROPDOWN",
    MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
    CHECKBOXES: "CHECKBOXES",
    SIGNATURE: "SIGNATURE",
};

export const FORM_FIELD_LABELS_BY_TYPE = {
    [FORM_FIELD_TYPES.SHORT_ANSWER]: "Short Answer",
    [FORM_FIELD_TYPES.PARAGRAPH]: "Paragraph",
    [FORM_FIELD_TYPES.DROPDOWN]: "Dropdown",
    [FORM_FIELD_TYPES.MULTIPLE_CHOICE]: "Multiple Choice",
    [FORM_FIELD_TYPES.CHECKBOXES]: "Checkboxes",
    [FORM_FIELD_TYPES.SIGNATURE]: "Signature",
};

export const EVENT_KEYS = {
    BULLETIN_POSTS_VIEWED: "bulletin_post_viewed",
};

export const X_CLIENT_TYPE = "web_worker";

export const EVENT_DOMAINS = {
    USER_EVENTS: "user_events",
};

export const GENERATE_ZIP_URL = `${process.env.CDN_LINKS_URL}/generate/zip`;

export const CONTENT_SECTIONS_MENU_CONFIG_FLAGS = {
    share: true,
};

export const SAVINGS_DEFAULT_INPUTS = {
    recruiting: {
        avg_recruiting_expense: 4500,
        referral_bonus: 500,
        fte_churn_percent: 40,
        number_of_ftes: 500,
        hires_from_referrals_percent: 25,
    },
    retention: {
        onboarding_and_training_hours: 20,
        avg_hourly_wage: 55,
        annual_hours_per_fte: 2080,
        burdens_and_benefits_percent: 20,
        sg_a_percent: 13,
        profit_percent: 10,
        reduction_in_churn_percent: 25,
    },
    total: {
        default_subscription_cost: 25000,
        custom_subscription_cost: 0,
    },
};

export const MATH_CHARS = {
    MULTIPLICATION: "x",
    ADDITION: "+",
    DIVISION: "÷",
    SUBTRACTION: "-",
};

export const EXPECTED_SAVINGS_DISPLAY_VALUES = {
    FTE_CHURN: "200",
    HIRES_FROM_REFERRALS_PERCENT: `${MATH_CHARS.MULTIPLICATION} 25%`,
    SAVINGS_PER_REFERRAL_HIRE: `${MATH_CHARS.MULTIPLICATION} $4,000`,
    ANNUAL_RECRUITING_SAVINGS: "$200,000",
    COST_TO_HIRE_CHURNED_FTES: `${MATH_CHARS.DIVISION} $900,000`,
    ANNUAL_RECRUITING_SAVINGS_PERCENT: "22.22%",
    ONBOARDING_TRAINING_TESTING: "$292,600",
    LOST_PROFIT: `${MATH_CHARS.ADDITION} $3,271,840`,
    ANNUAL_COST_IMPACT: "$3,564,440",
    ANNUAL_RETENTION_SAVINGS: "$891,110",
    ANNUAL_RETENTION_SAVINGS_PERCENT: "25%",
    RECRUITING_AND_RETENTION_SAVINGS: "$1,091,110",
    TOTAL_ANNUAL_SAVINGS: "$981,999",
    TOTAL_ANNUAL_SAVINGS_PERCENT: "90%",
};

export const TOOLTIP_ID = "tooltip-id";

export const MUI_CONSTANTS = {
    INFO_ICON: "InfoIcon",
};

export const WHY_BOOMNATION_TAB_VALUES = {
    RECRUITING: "recruiting",
    COMMUNITY: "community",
    COMMUNICATION: "communication",
};

export const COMPANY_TAB_VALUES = {
    WHO_WE_ARE: "who_we_are",
    OUR_ANTHEM: "our_anthem",
    OUR_STORY: "our_story",
};

export const LANDING_HORIZONTAL_PADDING = "10%";

// TODO: move to theme.js in lib-web-common
export const CONDENSED_LINE_HEIGHT = 1.2;

export const BUTTON_STYLE_OVERRIDE = { padding: "1px 10px !important " };

export const CAMPAIGN_FORM_TYPES = {
    RECRUITING_CAMPAIGN: "recruiting_campaign",
    GENERAL_CAMPAIGN: "general_campaign",
};

import { useEffect, useState } from "react";

import { useSendTransactionalSmsOptIn } from "~graphql/hooks/transactionalSmsConsent";
import { useCurrentUser } from "~graphql/hooks/users";

export function useTriggerOptIn() {
    const { user } = useCurrentUser();
    const { sendTransactionalSmsOptIn } = useSendTransactionalSmsOptIn();
    const [hasRun, setHasRun] = useState(false);

    // Once we have an authed user check if they have opted in/out and trigger
    // opt in and confirmation if not since by logging in they have accepted
    useEffect(() => {
        triggerOptIn({ user, hasRun, setHasRun, sendTransactionalSmsOptIn });
    }, [hasRun, user]);
}

// Exported for testing
export function triggerOptIn({
    user,
    hasRun,
    setHasRun,
    sendTransactionalSmsOptIn,
}) {
    if (!user || hasRun) return;
    const hasConsented = user.consent.transactional.has_consented;
    const hasNotOptedInOrOut = hasConsented === null;
    if (hasNotOptedInOrOut) {
        sendTransactionalSmsOptIn();
        setHasRun(true);
    }
}

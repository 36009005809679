import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

export default function SectionHeader({ children, hideHeaderBorder }) {
    return (
        <StyledSectionHeader hideHeaderBorder={hideHeaderBorder}>
            {children}
        </StyledSectionHeader>
    );
}
SectionHeader.propTypes = {
    children: PropTypes.node.isRequired,
    hideHeaderBorder: PropTypes.bool,
};

const StyledSectionHeader = styled(Box, {
    shouldForwardProp: (prop) => !["hideHeaderBorder"].includes(prop),
})(({ theme, hideHeaderBorder }) => ({
    borderBottom: hideHeaderBorder
        ? "none"
        : `1px solid ${theme.palette.text.secondary}`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
    },
}));

import styled from "@emotion/styled";
import AvatarOg from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import PropTypes from "prop-types";
import React from "react";

import UploadCameraIcon from "~shared/Form/Inputs/ImageUploadInput/UploadCameraIcon";

export default function AvatarImageInputPreview({ image }) {
    return (
        <Badge
            badgeContent={<UploadCameraIcon />}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            overlap="circular"
        >
            <Avatar src={image} />
        </Badge>
    );
}

AvatarImageInputPreview.propTypes = {
    image: PropTypes.string,
};

const Avatar = styled(AvatarOg)(() => ({
    width: "100px",
    height: "100px",
}));

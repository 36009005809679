import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useNavigationHelpers } from "~app/navigationHelpers";
import { DEEP_LINK_ENTITY_TYPES, DEEP_LINK_TYPES } from "~constants/constants";

export default function DeepLinksHubPage() {
    const location = useLocation();
    const navigators = useNavigationHelpers();

    useEffect(() => {
        const {
            type,
            entity_type: entityType,
            entity_id: entityId,
        } = queryString.parse(location.search);

        handleDeeplink({ type, entityType, entityId, navigators });
    }, [location, navigators]);

    return null;
}

function handleDeeplink({ type, entityType, entityId, navigators }) {
    if (type === DEEP_LINK_TYPES.REDIRECT) {
        redirectToDeeplink({ entityType, entityId, navigators });
    } else {
        navigators.goToAppStore();
    }
}

function redirectToDeeplink({ entityType, entityId, navigators }) {
    switch (entityType) {
        case DEEP_LINK_ENTITY_TYPES.JOB:
            navigators.goToJob({ jobId: entityId });
            break;
        case DEEP_LINK_ENTITY_TYPES.COLLECTION:
            navigators.goToCollection({ collectionId: entityId });
            break;
        case DEEP_LINK_ENTITY_TYPES.COMPANY:
            navigators.goToCompany({ companyId: entityId });
            break;
        case DEEP_LINK_ENTITY_TYPES.USER:
            navigators.goToUserProfile({ userId: entityId });
            break;
        case DEEP_LINK_ENTITY_TYPES.JOBS:
            navigators.goToJobs();
            break;
        case DEEP_LINK_ENTITY_TYPES.EDIT_PROFILE:
            navigators.goToOwnProfile();
            break;
        case DEEP_LINK_ENTITY_TYPES.CHAT_CHANNEL:
            navigators.goToMessages();
            break;
        case DEEP_LINK_ENTITY_TYPES.POST:
        case DEEP_LINK_ENTITY_TYPES.NOTIFICATIONS:
        case DEEP_LINK_ENTITY_TYPES.NOTIFICATIONS_PREFERENCES:
        default:
            navigators.goToAppStore();
            break;
    }
}

import { isString } from "@boomnation/lib-common";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

export default function InputErrorMessage({ id, validationMessage }) {
    if (!validationMessage) return null;

    return (
        <ErrorMessage
            aria-label={`error-${id}`}
            variant="caption"
            color="error.main"
        >
            {
                /* validationMessage can be an object that holds multiple errors or string
                show first error if object of errors else just the string */
                isString(validationMessage)
                    ? validationMessage
                    : Object.values(validationMessage)[0].message
            }
        </ErrorMessage>
    );
}

InputErrorMessage.propTypes = {
    id: PropTypes.string,
    validationMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

const ErrorMessage = styled(Typography)({
    marginLeft: "6px",
});

import { useTheme } from "@boomnation/lib-web-common";
import useMediaQuery from "@mui/material/useMediaQuery";
import queryString from "query-string";
import { useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";

import { ROUTES } from "~constants/constants";
import { validateUuid } from "~utils/utils";

export function useScreenSize() {
    const { breakpoints } = useTheme();
    const isSmScreen = useMediaQuery(breakpoints.down("sm"));
    const isMdScreen = useMediaQuery(breakpoints.down("md"));
    const isLargeScreen = useMediaQuery(breakpoints.down("lg"));
    const isXlScreen = useMediaQuery(breakpoints.down("xl"));

    return { isSmScreen, isMdScreen, isLargeScreen, isXlScreen };
}

export function useLocationQuery() {
    const { search } = useLocation();

    return queryString.parse(search);
}

export function useLocationState() {
    const { state } = useLocation();

    return state || {};
}

export function useIsDarkMode() {
    const theme = useTheme();

    return theme.palette.isDarkMode;
}

export function useModalControls({ onOpen, onClose } = {}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
        onOpen?.();
    };
    const closeModal = () => {
        setIsModalOpen(false);
        onClose?.();
    };

    return { isModalOpen, openModal, closeModal };
}

export function useIdParamWithValidation({
    validateIdFunc = validateUuid,
    redirectRoute = ROUTES.LANDING,
}) {
    const history = useHistory();

    const { id } = useParams();
    const isValid = validateIdFunc(id);

    if (!isValid) {
        history.push(redirectRoute);
    }

    return isValid ? id : null;
}

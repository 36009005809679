import {
    uniqueBy,
    getEntitiesWithSearchAndSortMerge,
    getEntitiesWithSearchAndSortRead,
} from "@boomnation/lib-common";

export function getTypePolicies() {
    return {
        Query: {
            fields: {
                getBulletinPosts: {
                    keyArgs: ["entityType", "entityId"],
                    merge: mergePaginatedQuery,
                    read: readPaginatedQuery,
                },
                getWorkerAssignedProjects: {
                    merge: mergePaginatedQuery,
                    read: readPaginatedQuery,
                },
                getWorkerProjects: {
                    keyArgs: ["companyId", "searchKeyword"],
                    merge: getEntitiesWithSearchAndSortMerge,
                    read: getEntitiesWithSearchAndSortRead,
                },
            },
        },
    };
}

// COMMON

export function mergePaginatedQuery(existing, incoming, { args }) {
    const existingResults = existing?.results || [];
    const { results: incomingResults, meta } = incoming;

    const results = addIncomingToExistingResults({
        existingResults,
        incomingResults,
        offset: args.offset,
    });

    return { results, meta };
}

export function readPaginatedQuery(existing) {
    return existing;
}

function addIncomingToExistingResults({
    existingResults,
    incomingResults,
    offset,
}) {
    const newResults =
        offset > 0
            ? uniqueBy([...existingResults, ...incomingResults], "__ref")
            : incomingResults;

    return newResults;
}

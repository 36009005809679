import gql from "graphql-tag";

import { TRANSLATION } from "~graphql/schemas/translationSchemas";

export const GET_TRANSLATION = gql`
    query GetTranslation($text: String!, $to_language: Language!, $from_language: Language) {
        getTranslation(text: $text, to_language: $to_language, from_language: $from_language) {
            ${TRANSLATION}
        }
    }
`;

import gql from "graphql-tag";

import { USER } from "~graphql/schemas/userSchemas";

export const UPDATE_USER = gql`
    mutation Mutation($id: String!, $update: UpdateUserInput!) {
        updateUser(id: $id, update: $update) {
            ${USER}
        }
    }
`;

import reactGA from "react-ga";

reactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);

function trackPageView({ url, path }) {
    reactGA.pageview(url + path);
}

export default {
    trackPageView,
};

import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete from "use-places-autocomplete";

import { useIsDarkMode } from "~app/hooks/utilHooks";
import {
    FORM_INPUTS,
    FORM_ERROR,
    ARIA_LABELS,
    MUI_SIZE,
} from "~constants/constants";
import { COPY } from "~constants/copyConstants";
import GOOGLE_ON_DARK_LOGO from "~images/google_on_non_white.png";
import GOOGLE_ON_WHITE_LOGO from "~images/google_on_white.png";
import { getAddress } from "~services/googleApiHelpers";
import AddressPlacesSuggestions from "~shared/Form/Inputs/AddressPlacesSuggestions";
import InputErrorMessage from "~shared/Form/Inputs/InputErrorMessage";
import InputTitle from "~shared/Form/Inputs/InputTitle";
import {
    paperOverrides,
    menuListOverrides,
    GoogleLogo,
} from "~shared/Form/Inputs/styledAddressFormInput";
import {
    StyledTextField,
    FullWidthFormControl,
} from "~shared/Form/Inputs/styledFormComponents";

export default function AddressFormTextInput({
    value: formVal,
    title,
    label,
    validationMessage,
    onChange,
    noBottomMargin,
    optionsListOverrides,
    placesRequestOptions,
    ...props
}) {
    const inputRef = useRef();
    const initVal = getInitialValue(formVal);
    const addressId = formVal?.id;
    const { t } = useTranslation();
    const OK = "OK";
    const isDarkTheme = useIsDarkMode();

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({ requestOptions: placesRequestOptions });
    const showOptions = status === OK && value !== initVal;

    const handleInput = (e) => {
        // if user clears input, reset form value
        if (e.target.value.length === 0) {
            onChange(addressId ? { id: addressId } : null);
        }
        setValue(e.target.value);
    };

    // if existing value exists, set value equal to it
    useEffect(() => {
        if (formVal) {
            setValue(initVal);
        }
    }, []);

    const handleSelect =
        ({ description }) =>
        () => {
            // focus on input so onBlur error will show once user clicks away from input
            inputRef.current.focus();
            addAddress(
                addressId,
                description,
                setValue,
                clearSuggestions,
                onChange
            );
        };

    return (
        <FullWidthFormControl noBottomMargin={noBottomMargin}>
            {title && <InputTitle>{title}</InputTitle>}
            <StyledTextField
                {...props}
                inputRef={inputRef}
                name={`${FORM_INPUTS.ADDRESS}-search`}
                placeholder={label}
                autoComplete="off"
                id={FORM_INPUTS.ADDRESS}
                aria-label={FORM_INPUTS.ADDRESS}
                value={value}
                onChange={handleInput}
                disabled={!ready}
                size={MUI_SIZE.SMALL}
                error={!!validationMessage}
            />
            {showOptions && (
                <Paper sx={{ ...paperOverrides, ...optionsListOverrides }}>
                    <MenuList dense sx={menuListOverrides}>
                        <AddressPlacesSuggestions
                            data={data}
                            handleSelect={handleSelect}
                        />
                        <GoogleLogo
                            src={
                                isDarkTheme
                                    ? GOOGLE_ON_DARK_LOGO
                                    : GOOGLE_ON_WHITE_LOGO
                            }
                            alt={COPY.POWERED_BY_GOOGLE.EN}
                        />
                    </MenuList>
                </Paper>
            )}
            <InputErrorMessage
                id={ARIA_LABELS.ADDRESS_ERROR}
                validationMessage={
                    validationMessage && t(FORM_ERROR.FULL_ADDRESS)
                }
            />
        </FullWidthFormControl>
    );
}

async function addAddress(
    addressId,
    description,
    setValue,
    clearSuggestions,
    onChange
) {
    setValue(description, false);
    clearSuggestions();
    try {
        const address = await getAddress(description);
        if (addressId) {
            address.id = addressId;
        }
        onChange(address);
    } catch (err) {
        onChange(addressId ? { id: addressId } : null);
    }
}

function getInitialValue(address) {
    if (!address?.city) return "";

    const lineOneOrEmpty = address.line_1 ? `${address.line_1}, ` : "";

    return `${lineOneOrEmpty}${address?.city}, ${address?.state}`;
}

AddressFormTextInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    validationMessage: PropTypes.any,
    optionsListOverrides: PropTypes.object,
    noBottomMargin: PropTypes.bool,
    placesRequestOptions: PropTypes.object,
};

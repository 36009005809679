import PropTypes from "prop-types";
import React from "react";

import InputErrorMessage from "~shared/Form/Inputs/InputErrorMessage";
import InputTitle from "~shared/Form/Inputs/InputTitle";
import {
    StyledTextField,
    FullWidthFormControl,
} from "~shared/Form/Inputs/styledFormComponents";
import TextWithTranslateButton from "~shared/TextWithTranslateButton";

export default function FormTextInput({
    title,
    i18n,
    propertyName,
    label,
    id,
    value,
    validationMessage,
    variant = "standard",
    onChange,
    placeholder,
    isDisabled,
    inputTitleVariant,
    ...props
}) {
    const shouldShowBasicTitle = title && !i18n;

    return (
        <FullWidthFormControl>
            {shouldShowBasicTitle && (
                <InputTitle variant={inputTitleVariant}>{title}</InputTitle>
            )}
            {i18n && (
                <TextWithTranslateButton
                    text={title}
                    i18n={i18n}
                    propertyName={propertyName}
                    TextComponent={InputTitle}
                />
            )}
            <StyledTextField
                {...props}
                data-testid={id}
                id={id}
                variant={variant}
                label={label}
                placeholder={placeholder || label}
                aria-label={id}
                onChange={(event) => onChange(event.target.value)}
                error={!!validationMessage}
                value={value || ""}
                disabled={isDisabled}
            />
            <InputErrorMessage id={id} validationMessage={validationMessage} />
        </FullWidthFormControl>
    );
}

FormTextInput.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    validationMessage: PropTypes.string,
    isDisabled: PropTypes.bool,
    i18n: PropTypes.array,
    propertyName: PropTypes.string,
    variant: PropTypes.string,
    placeholder: PropTypes.string,
    inputTitleVariant: PropTypes.string,
};

import { useTranslation as useTranslationi18n } from "react-i18next";

import { useLazyQuery } from "~graphql/hooks/shared";
import { GET_TRANSLATION } from "~graphql/queries/translations";

export function useTranslation(onCompleted) {
    const { i18n } = useTranslationi18n();
    const preferredLanguage = i18n.language;

    const [translateQuery, { error, loading: isLoading, data }] = useLazyQuery(
        GET_TRANSLATION,
        {
            onCompleted,
            boomnation: {
                showSnackbarOnError: true,
            },
        }
    );

    const translation = data?.getTranslation;

    const translate = ({ text, toLanguage = preferredLanguage }) => {
        translateQuery({
            variables: { text, to_language: toLanguage },
        });
    };

    return {
        translate,
        error,
        isLoading,
        translation,
    };
}

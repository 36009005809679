import * as yup from "yup";

import {
    FORM_ERROR,
    FORM_INPUT_LABELS,
    FORM_INPUT_TYPES,
} from "~constants/constants";
import { getStartAtAndEndAtValidation } from "~services/validationHelpers";
import { getPhoneNumberValidator } from "~shared/Form/Inputs/PhoneNumberInput";
import { getEmailValidator } from "~shared/Form/yupHelpers";

export function getAccountInfoValidationSchema() {
    return yup.object().shape({
        first_name: yup.string().required(FORM_ERROR.REQUIRED),
        last_name: yup.string().required(FORM_ERROR.REQUIRED),
        email: yup.string().email().required(FORM_ERROR.EMAIL),
        phone_number: getPhoneNumberValidator(),
    });
}

export function getBioValidationSchema() {
    return yup.object().shape({
        bio: yup
            .string()
            .max(300, FORM_ERROR.BIO)
            .label(FORM_INPUT_LABELS.BIO)
            .nullable()
            .required(FORM_ERROR.REQUIRED),
    });
}

export function getEducationValidationSchema() {
    return yup.object().shape({
        school: yup
            .object()
            .shape({ id: yup.string().nullable(), name: yup.string() })
            .test({
                name: "school",
                test: (value) => !!value?.id || !!value?.name,
                message: FORM_ERROR.REQUIRED,
            }),
        degree_name: yup
            .string()
            .label(FORM_INPUT_LABELS.DIPLOMA)
            .required(FORM_ERROR.REQUIRED),
        location_description: yup
            .string()
            .label(FORM_INPUT_LABELS.LOCATION)
            .required(FORM_ERROR.REQUIRED),
        ...getStartAtAndEndAtValidation(),
    });
}

export function getEmploymentValidationSchema() {
    return yup.object().shape({
        position: yup
            .string()
            .label(FORM_INPUT_LABELS.JOB_TITLE)
            .required(FORM_ERROR.REQUIRED),
        place_of_employment: yup
            .object()
            .shape({ id: yup.string().nullable(), name: yup.string() })
            .test({
                name: "place_of_employment",
                test: (value) => !!value?.id || !!value?.name,
                message: FORM_ERROR.REQUIRED,
            }),
        location_description: yup
            .string()
            .label(FORM_INPUT_LABELS.LOCATION)
            .required(FORM_ERROR.REQUIRED),
        ...getStartAtAndEndAtValidation(),
    });
}

export function getUserTradeValidationSchema() {
    return yup.object().shape({
        trade: yup
            .object()
            .shape({
                id: yup.string().required(FORM_ERROR.REQUIRED),
                name: yup.string(),
            })
            .label(FORM_INPUT_LABELS.TRADE),
        years_of_experience: yup
            .number()
            .nullable()
            .required(FORM_ERROR.REQUIRED),
        is_primary: yup.boolean().required(FORM_ERROR.REQUIRED),
    });
}

export function getVaccinationValidationSchema() {
    return yup.object({
        name: yup.string().label("Name").required(FORM_ERROR.REQUIRED),
        manufacturer: yup
            .string()
            .label(FORM_INPUT_LABELS.VACCINATION_MANUFACTURER)
            .required(FORM_ERROR.REQUIRED),
        first_dose_at: yup
            .number()
            .label(FORM_INPUT_LABELS.FIRST_DOSE_DATE)
            .required(FORM_ERROR.REQUIRED),
        second_dose_at: yup
            .number()
            .label(FORM_INPUT_LABELS.SECOND_DOSE_DATE)
            .nullable(),
        latest_booster_at: yup
            .number()
            .label(FORM_INPUT_LABELS.LATEST_BOOSTER_DATE)
            .nullable(),
    });
}

export function getResumeSchema() {
    return yup.object({
        name: yup.string().label("Name").required(),
        description: yup.string().label("Description"),
        // Yup interprets the file from the picker as null when using object.
        attachment_id: yup.mixed(),
    });
}

export function getCardValidationSchema() {
    return yup.object({
        name: yup.string().label("Name"),
        description: yup.string().label("Description"),
        // Yup interprets the file from the picker as null when using object.
        attachment_id: yup.mixed(),
    });
}

export function getCertificationValidationSchema({ certification }) {
    const fieldsValidation = getCertificationFieldsValidation({
        fields: certification?.fields,
    });

    return yup.object().shape({ ...fieldsValidation });
}

export function getCertificationFieldsValidation({ fields }) {
    return fields?.reduce((validations, field) => {
        const validation = getValidationValues({
            type: field.field_type.type,
        }).label(`${field.label}`);

        return {
            ...validations,
            [field.key]: field.is_required
                ? validation.required(FORM_ERROR.REQUIRED)
                : validation,
        };
    }, {});
}

function getValidationValues({ type }) {
    switch (type) {
        case FORM_INPUT_TYPES.MULTISELECT_ENUM:
            return yup.string().min(1);
        case FORM_INPUT_TYPES.API_ENUM:
            return yup.string();
        case FORM_INPUT_TYPES.ENUM:
            return yup.string();
        case FORM_INPUT_TYPES.ATTACHMENT_URL:
            // Yup interprets the file from the picker as null when using object.
            return yup.mixed();
        case FORM_INPUT_TYPES.DATE:
            return yup.number();
        default:
            return yup[type]();
    }
}

export function getEditWorkValidationSchema(user) {
    return yup.object().shape({
        first_name: yup
            .string()
            .max(300, FORM_ERROR.FIRST_NAME)
            .label(FORM_INPUT_LABELS.FIRST_NAME)
            .required(FORM_ERROR.REQUIRED),
        last_name: yup
            .string()
            .max(300, FORM_ERROR.LAST_NAME)
            .label(FORM_INPUT_LABELS.LAST_NAME)
            .required(FORM_ERROR.REQUIRED),
        email: getEmailValidator(user),
        phone_number: getPhoneNumberValidator(),
        profile_image: yup
            .string()
            .label(FORM_INPUT_LABELS.PROFILE_IMAGE)
            .nullable(),
    });
}

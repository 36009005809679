import { makeVar, useReactiveVar } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackBar } from "@boomnation/lib-web-common";
import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";

import { writeForwardingState } from "~services/localStorage";

// Exported for testing only.
export const tokenVar = makeVar();

let isAlreadyGettingToken = false;

export function useIsAuthenticated() {
    const { isAuthenticated } = useAuth();

    return isAuthenticated;
}

export function useHasBeenAuthenticated() {
    const { hasBeenAuthenticated } = useAuth();

    return hasBeenAuthenticated;
}

export function useAuthToken() {
    const { token } = useAuth();

    return token;
}

export function useAuth() {
    const {
        isAuthenticated: isAuthenticatedAuth0,
        getAccessTokenSilently,
        isLoading: isAuthLoading,
    } = useAuth0();
    const token = useReactiveVar(tokenVar);
    const [error, setError] = useState();

    useEffect(() => {
        if (isAuthenticatedAuth0) {
            getAndSetAccessToken({
                getAccessTokenSilently,
                setError,
            });
        }
    }, [isAuthenticatedAuth0, getAccessTokenSilently]);

    const isAuthenticated = isAuthenticatedAuth0 && !!token;
    const isTokenLoading = !isAuthenticated && isAuthenticatedAuth0;
    const isLoading = isAuthLoading || isTokenLoading;

    return {
        token,
        error,
        isAuthenticated,
        hasBeenAuthenticated: isAuthenticatedAuth0,
        isLoading,
    };
}

async function getAndSetAccessToken({ getAccessTokenSilently, setError }) {
    if (isAlreadyGettingToken) return;
    isAlreadyGettingToken = true;

    try {
        const accessToken = await getAccessTokenSilently();
        tokenVar(accessToken);
    } catch (e) {
        setError(e);
    } finally {
        isAlreadyGettingToken = false;
    }
}

export function useLoginWithRedirect() {
    const { loginWithRedirect } = useAuth0();
    const { showError } = useSnackBar();

    return () => {
        const state = {
            traceId: uuid(),
            returnTo: window?.location?.pathname,
            searchString: window?.location?.search,
        };

        writeForwardingState(state);

        loginWithRedirect().catch((error) => showError({ error }));
    };
}

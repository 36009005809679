import { isString } from "@boomnation/lib-common";

import { logError } from "~services/sentry";

export async function makeRequest({
    url,
    method,
    includeAuthHeader,
    body,
    contentType,
    token,
    shouldParseAsBlob,
}) {
    try {
        const response = await makeRequestCore({
            url,
            method,
            includeAuthHeader,
            body,
            contentType,
            token,
            shouldParseAsBlob,
        });

        return response;
    } catch (originalError) {
        const error = formatAndLogRequestError(originalError);
        throw error;
    }
}
async function makeRequestCore({
    url,
    method = "GET",
    includeAuthHeader,
    body,
    contentType,
    token,
    shouldParseAsBlob,
}) {
    const headers = await getRequestHeaders({
        includeAuthHeader,
        contentType,
        token,
    });
    const response = await fetch(url, { method, headers, body });

    if (!response.ok) {
        const error = await parseJsonResponseSafely(response);

        throw error;
    }

    const responseBody = await parseResponseBody({
        response,
        shouldParseAsBlob,
    });

    return responseBody;
}

async function getRequestHeaders({
    includeAuthHeader = true,
    contentType = "application/json",
    token,
}) {
    const commonHeaders = { "Content-Type": contentType };

    if (!includeAuthHeader) return commonHeaders;

    return {
        Authorization: `Bearer ${token}`,
        ...commonHeaders,
    };
}

function formatAndLogRequestError(originalError) {
    const error = new Error(originalError.message || originalError.statusText);
    const textErrorResponse = isString(originalError) ? originalError : null;
    const extra = {
        debug: JSON.stringify(originalError.debug || {}),
        level: originalError.level,
        statusCode: originalError.statusCode,
        status: originalError.status,
        code: originalError.code,
        textErrorResponse,
    };

    logError({ error, extra });

    return error;
}

async function parseResponseBody({ response, shouldParseAsBlob }) {
    if (shouldParseAsBlob) {
        const blob = await response.blob();

        return blob;
    }

    const parsedBody = await parseJsonResponseSafely(response);

    return parsedBody;
}

// Tries to parse as JSON, if that fails, returns text body.
async function parseJsonResponseSafely(response) {
    const stringBody = await response.text();

    try {
        const jsonBody = JSON.parse(stringBody);

        return jsonBody;
    } catch {
        return stringBody;
    }
}

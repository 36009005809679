import {
    ENTITY_TYPES,
    DEFAULT_WORKER_ASSIGNED_LIMIT,
} from "~constants/constants";
import { getSortWithKeywordSuffix, getMoreEntities } from "~graphql/helpers";
import { useQuery } from "~graphql/hooks/shared";
import { useCurrentUser } from "~graphql/hooks/users";
import {
    GET_WORKER_PROJECTS,
    GET_WORKER_ASSIGNED_PROJECTS,
    GET_PROJECT,
    GET_PROJECT_NAME,
} from "~graphql/queries/projects";

export function useWorkerProjects({
    rowsPerPage,
    page,
    sortedBy,
    companyId,
    searchKeyword,
}) {
    const offset = page * rowsPerPage;
    const KEYWORD_COLUMNS = ["name", "location_city"];

    const sort = getSortWithKeywordSuffix({
        keywordColumns: KEYWORD_COLUMNS,
        hasSearchKeyword: !!searchKeyword,
        sort: sortedBy,
        entityPrefix: ENTITY_TYPES.PROJECTS,
    });

    const { error, loading, data, refetch } = useQuery(GET_WORKER_PROJECTS, {
        variables: {
            companyId,
            searchKeyword,
            limit: rowsPerPage,
            offset,
            sort,
        },
        notifyOnNetworkStatusChange: true,
    });

    const projects = data?.getWorkerProjects?.results;
    const total = data?.getWorkerProjects?.meta?.total;

    return { error, isLoading: loading, refetch, projects, total };
}

export function useGetWorkerAssignedProjects() {
    const { user } = useCurrentUser();
    const {
        error,
        loading: isLoading,
        data,
        refetch,
        fetchMore,
    } = useQuery(GET_WORKER_ASSIGNED_PROJECTS, {
        variables: {
            limit: DEFAULT_WORKER_ASSIGNED_LIMIT,
            offset: 0,
        },
        skip: !user?.id,
    });

    const projects = data?.getWorkerAssignedProjects?.results;
    const total = data?.getWorkerAssignedProjects?.meta?.total;

    return {
        error,
        isLoading,
        refetch,
        projects,
        total,
        getMoreAssignedProjects: () =>
            getMoreEntities({
                entities: projects,
                total,
                isLoading,
                fetchMore,
            }),
    };
}

export function useProject({ id, skip }) {
    const { error, loading, data, refetch } = useQuery(GET_PROJECT, {
        variables: { id },
        skip: !id || skip,
    });

    const project = data?.getProject;

    return { project, isLoading: loading, error, refetch };
}

export function useProjectName({ id, skip }) {
    const { error, loading, data, refetch } = useQuery(GET_PROJECT_NAME, {
        variables: { id },
        skip: !id || skip,
    });

    const projectName = data?.getProjectName?.name;
    const companyName = data?.getProjectName?.company_name;
    const companyImageUrl = data?.getProjectName?.company_image_url;

    return {
        projectName,
        companyName,
        companyImageUrl,
        isLoading: loading,
        error,
        refetch,
    };
}

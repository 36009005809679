/* eslint max-lines: ["error", {"max": 1500}] */

export const COPY = {
    CONTINUE: {
        EN: "Continue",
        ES: "Continuar",
    },
    ADD: {
        EN: "Add",
        ES: "Agregar",
    },
    IN: {
        EN: "in",
        ES: "en",
    },
    WORK: {
        EN: "Work",
        ES: "Trabajo",
    },
    BIO: {
        EN: "Bio",
        ES: "Biografía",
    },
    NAME: {
        EN: "Name",
        ES: "Nombre",
    },
    FIRST_NAME: {
        EN: "First Name",
        ES: "Primer Nombre",
    },
    LAST_NAME: {
        EN: "Last Name",
        ES: "Apellido",
    },
    CARD: {
        EN: "Card",
        ES: "Tarjeta",
    },
    JOBS: {
        EN: "Jobs",
        ES: "Trabajos",
    },
    COMPANY: {
        EN: "Company",
        ES: "Compañia",
    },
    COMPANY_JOBS: {
        EN: "Company Jobs",
        ES: "Trabajos de la Compañia",
    },
    COMPANY_COVER: {
        EN: "Company Cover",
        ES: "Cobertura de compañia",
    },
    SIMILAR_JOBS: {
        EN: "Similar Jobs",
        ES: "Trabajos Similares",
    },
    JOB_DESCRIPTION: {
        EN: "Job Description",
        ES: "Descripción de Trabajo",
    },
    DESCRIPTION: {
        EN: "Description",
        ES: "Descripción",
    },
    JOB_DESCRIPTION_NOT_PROVIDED: {
        EN: "Job Description Not Provided",
        ES: "Descripción de Trabajo no Proporcionada",
    },
    TRADES_REQUIRED: {
        EN: "Trades Required",
        ES: "Habilidades Requeridas",
    },
    RECENT_JOBS: {
        EN: "Recent Jobs",
        ES: "Trabajos Recientes",
    },
    RESULTS: {
        EN: "Results",
        ES: "Resultados",
    },
    FROM_OUR_PARTNERS: {
        EN: "From Our Partners",
        ES: "De Nuestros Socios",
    },
    CERTIFICATIONS: {
        EN: "Certifications",
        ES: "Certificaciones",
    },
    EDUCATION: {
        EN: "Education",
        ES: "Educación",
    },
    EXPERIENCE: {
        EN: "Experience",
        ES: "Experiencia",
    },
    INFORMATION: {
        EN: "Information",
        ES: "Información",
    },
    RESUME: {
        EN: "Resume",
        ES: "Hoja de Vida",
    },
    RESUMES: {
        EN: "Resumes",
        ES: "Hojas de Vida",
    },
    TRADES: {
        EN: "Trades",
        ES: "Habilidades",
    },
    TRADE: {
        EN: "Trade",
        ES: "Habilidad",
    },
    ADD_YOUR_BIO: {
        EN: "Add your bio",
        ES: "Añade tu Biografía",
    },
    MY_CARDS: {
        EN: "My Cards",
        ES: "Mis Tarjetas",
    },
    YOUR_DIGITAL_WALLET_IS_ONLY_VISIBLE_TO_EMPLOYERS: {
        EN: "Your digital wallet is only visible to employers.",
        ES: "Tu billetera digital solo es visible para los empleadores.",
    },
    ADD_YOUR_CARDS: {
        EN: "Add your cards",
        ES: "Añade tus tarjetas",
    },
    ADD_YOUR_CERTIFICATIONS: {
        EN: "Add your certifications",
        ES: "Añade tus certificaciones",
    },
    ADD_YOUR_EDUCATION: {
        EN: "Add your education",
        ES: "Añade tu educacion",
    },
    ADD_YOUR_EXPERIENCES: {
        EN: "Add your experiences",
        ES: "Añade tu experiencia",
    },
    ADD_YOUR_RESUMES: {
        EN: "Add your resumes",
        ES: "Añade tus hojas de vida",
    },
    TRADES_AND_INDUSTRIES: {
        EN: "Trade & Industries",
        ES: "Habilidades e Industrias",
    },
    ADD_YOUR_TRADES: {
        EN: "Add your trades",
        ES: "Añade tus habilidades",
    },
    VACCINATION_RECORDS: {
        EN: "Vaccination Records",
        ES: "Vacunaciones",
    },
    ADD_YOUR_VACCINATIONS: {
        EN: "Add your vaccinations",
        ES: "Añade tus Vacunaciones",
    },
    PROFILE_COMPLETION: {
        EN: "Profile Completion",
        ES: "Progreso de Perfil",
    },
    APPLIED_FOR_JOBS: {
        EN: "Applied For Jobs",
        ES: "Trabajos Aplicados",
    },
    CERTIFICATION: {
        EN: "Certification",
        ES: "Certificaciones",
    },
    WORK_EXPERIENCE: {
        EN: "Work Experience",
        ES: "Experiencia de trabajo",
    },
    EDIT_PROFILE: {
        EN: "Edit Profile",
        ES: "Edita tu Perfil",
    },
    POWERED_BY_GOOGLE: {
        EN: "Powered by Google",
        ES: "Powered by Google",
    },
    VACCINATION: {
        EN: "Vaccination",
        ES: "Vacunaciones",
    },
    APPLE_APP_STORE: {
        EN: "Apple App Store",
        ES: "Apple App Store",
    },
    GOOGLE_PLAY_STORE: {
        EN: "Google Play Store",
        ES: "Google Play Store",
    },
    RECOMMENDED_JOBS: {
        EN: "Recommended Jobs",
        ES: "Trabajos Recomendados",
    },
    SKILLS: {
        EN: "Skills",
        ES: "Habilidades",
    },
    ABOUT: {
        EN: "About",
        ES: "Acerca",
    },
    TRANSLATE: {
        EN: "Translate",
        ES: "Traducir",
    },
    SHOW_ORIGINAL: {
        EN: "Show Original",
        ES: "Mostrar original",
    },
    EMPLOYER_LOGIN: {
        EN: "Employer Log in",
        ES: "Portal de empleadores",
    },
    LOGIN: {
        EN: "Log in",
        ES: "Inicia sesión",
    },
    SIGN_OUT: {
        EN: "Sign out",
        ES: "Cerrar sesión",
    },
    DOWNLOAD_BOOMNATION: {
        EN: "Download BoomNation",
        ES: "Descarga BoomNation",
    },
    DOWNLOAD: {
        EN: "Download",
        ES: "Descargar",
    },
    CONTACT_US: {
        EN: "Contact Us",
        ES: "Contacta con nosotros",
    },
    THANKS_FOR_YOUR_APPLICATION: {
        EN: "Thank you for your application!",
        ES: "Gracias por tu aplicacion",
    },
    QUICK_APPLY_WITH_BOOMNATION: {
        EN: "QUICK APPLY WITH BOOMNATION:",
        ES: "APLICA RAPIDO CON BOOMNATION:",
    },
    APPLY_TO_MULTIPLE_JOBS_WITH_1_CLICK: {
        EN: "Apply to multiple jobs with 1 click",
        ES: "Aplica a múltiples trabajos con un clic",
    },
    CONNECT_WITH_EMPLOYERS_AND_WORKERS: {
        EN: "Connect with employers and workers",
        ES: "Conecta con empleadores y trabajadores",
    },
    READ_OUR_REVIEWS: {
        EN: "Read our reviews",
        ES: "Lee nuestras reseñas",
    },
    UNABLE_TO_LOAD_ATTACHMENT: {
        EN: "Unable to Load Attachment",
        ES: "No se pudo descargar el archivo",
    },
    COPYRIGHT: {
        EN: "(c) BoomNation 2022 | All Rights Reserved.",
        ES: "(c) BoomNation 2022 | Todos los derechos reservados.",
    },
    SORT: {
        EN: "Sort",
        ES: "Ordernar",
    },
    FILTER: {
        EN: "Filter",
        ES: "Filtrar",
    },
    CHANGE: {
        EN: "Change",
        ES: "Cambiar",
    },
    UPLOAD: {
        EN: "Upload",
        ES: "Subir",
    },
    QUICK_TIP_TRADES_PROFILE: {
        EN: "Quick tip: You can add more trades to your profile on the BoomNation app.",
        ES: "Puedes agregar más oficios y habilidades a tu perfil en BoomNation",
    },
    YOU_ARE_ABOUT_TO_APPLY: {
        EN: "You are about to apply to",
        ES: "Estas a punto de aplicar a",
    },
    OPENING_END: {
        EN: " opening.",
        ES: " abriendo.",
    },
    YOUR_PROFILE_WILL_BE_SUBMITTED_TO_THIS_COMPANY: {
        EN: "Your profile will be submitted to this company.",
        ES: " abriendo.",
    },
    YES_APPLY: {
        EN: "Yes, Apply",
        ES: "Si, aplicar",
    },
    JOIN_COMMUNITY: {
        EN: "Join the Community of Skilled Workers on BoomNation!",
        ES: "¡Únete a la comunidad de trabajadores calificados en BoomNation!",
    },
    POST_APPLY_MESSAGE: {
        EN: "Through the BoomNation app, you can update and add to your profile to stand out from the crowd and land that next gig. Additionally, now that you’ve created a profile, you can apply to multiple jobs on the app and submit your resume with one click!",
        ES: "A través de la aplicación BoomNation, puedes actualizar y añadir a tu perfil para destacarte entre la multitud y conseguir tu próximo trabajo. Además, ahora que has creado un perfil, puedes aplicar a múltiples trabajos con un solo clic.",
    },
    PAGE_NOT_FOUND: {
        EN: "Page Not Found",
        ES: "Página no encontrada",
    },
    APPLY: {
        EN: "Apply",
        ES: "Aplicar",
    },
    FILLED: {
        EN: "Filled",
        ES: "No Disponible",
    },
    EXPIRED: {
        EN: "Expired",
        ES: "Expirado",
    },
    EMPLOYERS: {
        EN: "Employers",
        ES: "Empleadores",
    },
    TERMS_OF_USE: {
        EN: "Terms of Use",
        ES: "Condiciones de Uso",
    },
    PRIVACY_POLICY: {
        EN: "Privacy Policy",
        ES: "Política de Privacidad",
    },
    REMOVE_APPLICATION: {
        EN: "Remove Application",
        ES: "Remover Aplicacion",
    },
    WORK_ON: {
        EN: "WorkOn",
        ES: "WorkOn",
    },
    VIEW_PROFILE: {
        EN: "View profile",
        ES: "Ver perfil",
    },
    LOCATION: {
        EN: "Location",
        ES: "Ubicación",
    },
    CLEAR_ALL: {
        EN: "Clear All",
        ES: "Resetear",
    },
    RECOMMENDED_TRADES: {
        EN: "Recommended Trades",
        ES: "Trabajos Recomendados",
    },
    TOP_TRADES: {
        EN: "Top Trades",
        ES: "Los Mejores Trabajos",
    },
    SEARCH_JOBS: {
        EN: "Search Jobs...",
        ES: "Busca Trabajos...",
    },
    JOBS_SORT_BY_LABEL_NEWEST: {
        EN: "Newest Jobs (Default)",
        ES: "Trabajos Nuevos (Por Defecto)",
    },
    JOBS_SORT_BY_LABEL_PER_DIEM: {
        EN: "Per Diem",
        ES: "Paga Diaria",
    },
    JOBS_SORT_BY_LABEL_PAY: {
        EN: "Highest Pay",
        ES: "Paga",
    },
    ADDITIONAL_QUESTIONS: {
        EN: "Additional Questions",
        ES: "Preguntas Adicionales",
    },
    WORKER_DETAILS: {
        EN: "Worker Details",
        ES: "Detalles del Trabajador",
    },
    ACCOUNT_INFORMATION: {
        EN: "Account Information",
        ES: "Informacion de Cuenta",
    },
    EDIT: {
        EN: "Edit",
        ES: "Editar",
    },
    REMOVE: {
        EN: "Remove",
        ES: "Borrar",
    },
    YEARS: {
        EN: "Years",
        ES: "Años",
    },
    ERROR_FORM_PHONE: {
        EN: "Please enter a valid phone number",
        ES: "Por favor ingresa un número de teléfono válido",
    },
    ERROR_FORM_REQUIRED: { EN: "Required", ES: "Requerido" },
    ERROR_FORM_EMAIL: {
        EN: "Please enter a valid email",
        ES: "Por favor introduce una dirección de correo electrónico válida",
    },
    ERROR_FORM_BIO: {
        EN: "Please keep bio under 300 characters",
        ES: "Maximo es 300 caracteres",
    },
    ERROR_FORM_FIRST_NAME: {
        EN: "First name must be under 300 characters",
        ES: "Maximo es 300 caracteres",
    },
    ERROR_FORM_LAST_NAME: {
        EN: "Last name must be under 300 characters",
        ES: "Maximo es 300 caracteres",
    },
    ERROR_FORM_LOCATION: {
        EN: "Location must be under 300 characters",
        ES: "Maximo es 300 caracteres",
    },
    ERROR_FORM_IMAGE: {
        EN: "Please enter a valid image",
        ES: "Imagen es inválida",
    },
    ERROR_FORM_FULL_ADDRESS: {
        EN: "Please enter full address",
        ES: "Ingresa una dirección completa",
    },
    ERROR_FORM_GOOGLE_PLACES: {
        EN: "An error occurred when trying to get addresses. Please try again.",
        ES: "Ocurrió un error al intentar obtener direcciones. Inténtalo de nuevo.",
    },
    ERROR_FORM_NUMBER: {
        EN: "Please enter a valid number",
        ES: "Por favor ingresa un número válido",
    },
    FORM_UPDATE_FORM_SUBMISSION: {
        EN: "Form successfully updated!",
        ES: "¡Formulario actualizado con éxito!",
    },
    INPUT_LABELS_BIO: { EN: "Bio", ES: "Biografía" },
    INPUT_LABELS_FIRST_NAME: { EN: "First Name", ES: "Primer nombre" },
    INPUT_LABELS_LAST_NAME: { EN: "Last Name", ES: "Apellido" },
    INPUT_LABELS_EMAIL: { EN: "Email", ES: "Correo electrónico" },
    INPUT_LABELS_SCHOOL: { EN: "School", ES: "Escuela" },
    INPUT_LABELS_DIPLOMA: { EN: "Diploma", ES: "Diploma" },
    INPUT_LABELS_LOCATION: { EN: "Location", ES: "Ubicación" },
    INPUT_LABELS_START_DATE: { EN: "Start Date", ES: "Fecha de inicio" },
    INPUT_LABELS_END_DATE: { EN: "End Date", ES: "Fecha final" },
    INPUT_LABELS_CURRENTLY_ATTENDING_SCHOOL: {
        EN: "I'm currently in school",
        ES: "Actual",
    },
    INPUT_LABELS_JOB_TITLE: { EN: "Job Title", ES: "Título profesional" },
    INPUT_LABELS_EMPLOYER: { EN: "Employer", ES: "Empleador" },
    INPUT_LABELS_CURRENTLY_WORKING_IN_ROLE: {
        EN: "I currently work in this role",
        ES: "Actual",
    },
    INPUT_LABELS_ANSWER: { EN: "Answer", ES: "Respuesta" },
    INPUT_LABELS_SEARCH_TRADE: { EN: "Search trade", ES: "Buscar habilidad" },
    INPUT_LABELS_YEARS_OF_EXPERIENCE: {
        EN: "Years of Experience",
        ES: "Años de experiencia",
    },
    INPUT_LABELS_SET_AS_PRIMARY_TRADE: {
        EN: "Set as Primary Trade",
        ES: "Establecer como habilidad principal",
    },
    INPUT_LABELS_UPLOAD_RESUME: {
        EN: "Upload a resume for employers to review",
        ES: "Sube una hoja de vida para que los empleadores lo revisen",
    },
    INPUT_LABELS_CERTIFICATION: {
        EN: "Type of Credential/Certification",
        ES: "Tipo de Credencial/Certificación",
    },
    INPUT_LABELS_CERTIFICATION_NAME: {
        EN: "Name of Certification",
        ES: "Nombre de la Certificación",
    },
    INPUT_LABELS_CERTIFICATION_NUMBER: {
        EN: "Certification Number",
        ES: "Numero de Certificación",
    },
    INPUT_LABELS_CERTIFICATION_DATE: {
        EN: "Certification Date",
        ES: "Fecha de certificación",
    },
    INPUT_LABELS_VACCINATION_NAME: {
        EN: "Vaccination Name",
        ES: "Nombre de vacunación",
    },
    INPUT_LABELS_VACCINATION_MANUFACTURER: {
        EN: "Name of Vaccine Manufacturer",
        ES: "Nombre del fabricante de la vacuna",
    },
    INPUT_LABELS_FIRST_DOSE_DATE: {
        EN: "1st Dose Date",
        ES: "Primera dosis Fecha",
    },
    INPUT_LABELS_SECOND_DOSE_DATE: {
        EN: "2nd Dose Date",
        ES: "Fecha de la segubda dosis",
    },
    INPUT_LABELS_LATEST_BOOSTER_DATE: {
        EN: "Lastest Booster Dose Date",
        ES: "Fecha de la última dosis de refuerzo",
    },
    INPUT_LABELS_PHONE_NUMBER: { EN: "Phone Number", ES: "Número de teléfono" },
    INPUT_LABELS_TRADE: { EN: "Trade", ES: "Comercio" },
    INPUT_LABELS_INDUSTRY: { EN: "Industry", ES: "Industria" },
    INPUT_LABELS_AVAILABLE_FOR_WORK: {
        EN: "Available for Work",
        ES: "Disponible para trabajar",
    },
    INPUT_LABELS_PROFILE_IMAGE: { EN: "Profile Image", ES: "Imagen de perfil" },
    NO_PROJECTS: {
        EN: "No Projects",
        ES: "Sin Proyectos",
    },
    PROJECTS: {
        EN: "Projects",
        ES: "Proyectos",
    },
    CARDS: {
        EN: "Cards",
        ES: "Tarjetas",
    },
    PEOPLE: {
        EN: "People",
        ES: "Gente",
    },
    COMPANY_BULLETIN: {
        EN: "Company Bulletin",
        ES: "Boletín de la empresa",
    },
    PROJECT_BULLETIN: {
        EN: "Project Bulletin",
        ES: "Boletín del proyecto",
    },
    PROMPT_USER_INFO_WORKER_TITLE: {
        EN: "You're about to join",
        ES: "Estás a punto de unirte a",
    },
    PROMPT_USER_INFO_WORKER_SUBTITLE: {
        EN: "But first, let's get some quick details...",
        ES: "Pero primero, veamos algunos detalles rápidos...",
    },
    PROMPT_USER_INFO_JOB_SEEKER_TITLE: {
        EN: "Welcome to BoomNation!",
        ES: "¡Bienvenidos a BoomNation!",
    },
    PROMPT_USER_INFO_JOB_SEEKER_SUBTITLE: {
        EN: "Fill out your profile to get started",
        ES: "Rellena tu perfil para empezar",
    },
    PROMPT_USER_INFO_SUBMIT_BUTTON_WORKER: {
        EN: "Join",
        ES: "Únete",
    },
    PROMPT_USER_INFO_SUBMIT_BUTTON_JOB_SEEKER: {
        EN: "Save",
        ES: "Guardar",
    },
    DETAILS: {
        EN: "Details",
        ES: "Detalles",
    },
    BULLETIN: {
        EN: "Bulletin",
        ES: "Boletín",
    },
    NEWSFEED: {
        EN: "Newsfeed",
        ES: "Noticias",
    },
    MESSAGES: {
        EN: "Messages",
        ES: "Mensajes",
    },
    MESSAGES_PAGE: {
        EN: "Message companies and other tradesmen on the mobile app.",
        ES: "Envía mensajes a empresas y otros comerciantes en la aplicación móvil.",
    },
    NEWSFEED_PAGE: {
        EN: "See posts from other users on the mobile app.",
        ES: "Ve publicaciones de otros usuarios en la aplicación móvil.",
    },
    DOWNLOAD_HERE: {
        EN: "Download here",
        ES: "Descarga aquí",
    },
    YEARS_OF_EXPERIENCE_REQUIRED: {
        EN: "Years of Experience Required",
        ES: "Años de experiencia requeridos",
    },
    LOOKING_FOR_WORK: {
        EN: "Looking for Work",
        ES: "Buscando trabajo",
    },
    CURRENTLY_EMPLOYED: {
        EN: "Currently Employed",
        ES: "Actualmente empleado",
    },
    FINISH_PROFILE: {
        EN: "Show off your skills. Finish that remaining",
        ES: "Muestra tus habilidades. Termina ese restante",
    },
    EMPTY_PROFILE_JOBS_TAB: {
        EN: `When your work status is set to "currently employed" we won't show you any recommended jobs.`,
        ES: `Cuando tu estatus de trabajo es "actualmente empleado" no se mostrará ningún recomendación de trabajo.`,
    },
    WELCOME: {
        EN: "Welcome!",
        ES: "Bienvenido!",
    },
    JOIN: {
        EN: "Join",
        ES: "Únete",
    },
    YOU_HAVE_BEEN_INVITED_TO_JOIN: {
        EN: "You've been invited to join",
        ES: "Has sido invitado a jon",
    },
    SITE_MAPS: {
        EN: "Site maps",
        ES: "Mapas del sitio",
    },
    BULLETINS: {
        EN: "Bulletins",
        ES: "Boletines",
    },
    ORIENTATION: {
        EN: "Orientation",
        ES: "Orientación",
    },
    CONTACTS: {
        EN: "Contacts",
        ES: "Contactos",
    },
    DIRECTIONS: {
        EN: "Directions",
        ES: "Direcciones",
    },
    LINKS: {
        EN: "Links",
        ES: "Enlaces",
    },
    LEAVE_COMPANY: {
        EN: "Leave Company",
        ES: "Dejar la empresa",
    },
    ARE_YOU_SURE_YOU_WANT_TO_LEAVE: {
        EN: "Are you sure you want to leave",
        ES: "Estás seguro de que quieres dejar",
    },
    THIS_ACTION_CANNOT_BE_UNDONE: {
        EN: "This action cannot be undone.",
        ES: "Esta acción no se puede deshacer.",
    },
    LEAVE: {
        EN: "Leave",
        ES: "Dejar",
    },
    MY_COMPANIES: {
        EN: "My Companies",
        ES: "Mis Empresas",
    },
    MY_PROJECTS: {
        EN: "My Projects",
        ES: "Mis Proyectos",
    },
    NO_ASSIGNED_PROJECTS: {
        EN: "You have not been assigned to any projects yet",
        ES: "Aún no se te ha asignado ningún proyecto",
    },
    NO_PROJECT_CARDS: {
        EN: "This project doesn't have any cards yet.",
        ES: "Este proyecto aún no tiene ninguna tarjeta.",
    },
    SHOW_MORE: {
        EN: "Show more",
        ES: "Mostrar más",
    },
    REFER_A_FRIEND: {
        EN: "Refer a friend!",
        ES: "¡Recomienda a un amigo!",
    },
    REFER_A_FRIEND_BODY: {
        EN: "Know someone who would be a good fit for this project? Send them this referral link and your employer will know you sent them!",
        ES: "¿Conoces a alguien que sería una buena opción para este proyecto? ¡Envíeles este enlace de referencia y su empleador sabrá que los envió!",
    },
    REFER_A_FRIEND_SMS_MESSAGE: {
        EN: "Hey! My company's hiring for a project and I thought you'd be a great fit. Apply here:",
        ES: "¡Hola! Mi empresa está contratando para un proyecto y pensé que serías perfecto. Aplica aquí:",
    },
    APPLY_ON_EXTERNAL_SITE: {
        EN: "Apply on External Site",
        ES: "Aplicar en sitio externo",
    },
    SAVINGS_CALCULATOR_HEADER: {
        EN: "Savings Calculator",
        ES: "Calculadora de Ahorros",
    },
    SAVINGS_CALCULATOR_SUB_HEADER: {
        EN: "See how much money you could save with WorkOn",
        ES: "Vea cuánto dinero podría ahorrar con WorkOn",
    },
    RECRUITING: {
        EN: "Recruiting",
        ES: "Reclutamiento",
    },
    AVG_RECRUITING_EXPENSE: {
        EN: "Average Recruiting Expense",
        ES: "Gasto Promedio de Reclutamiento",
    },
    REFERRAL_BONUS: {
        EN: "Referral Bonus - Paid to Referrer",
        ES: "Bono de Referencia - Pagado al Referente",
    },
    RETENTION: {
        EN: "Retention",
        ES: "Retención",
    },
    CALCULATE: {
        EN: "Show me how much I could save",
        ES: "Muéstrame cuánto podría ahorrar",
    },
    RECALCULATE: {
        EN: "Recalculate",
        ES: "Recalcular",
    },
    SAVINGS: {
        EN: "Savings",
        ES: "Ahorros",
    },
    ONBOARDING_AND_TRAINING_HOURS: {
        EN: "Onboarding/Testing/Training Hours",
        ES: "Horas de Incorporación/Pruebas/Capacitación",
    },
    AVG_HOURLY_WAGE: {
        EN: "Average Hourly Wage",
        ES: "Salario Promedio por Hora",
    },
    ANNUAL_HOURS_PER_FTE: {
        EN: "Annual Hours per FTE",
        ES: "Horas Anuales por FTE",
    },
    NUMBER_OF_FTES: {
        EN: "# of FTEs",
        ES: "# de FTEs",
    },
    BURDEN_AND_BENEFITS_PERCENT: {
        EN: "Burdens & Benefits",
        ES: "Cargas y Beneficios",
    },
    SG_A_PERCENT: {
        EN: "SG&A",
        ES: "SG&A ",
    },
    PROFIT_PERCENT: {
        EN: "Profit",
        ES: "Ganancia",
    },
    PROFIT_PER_FTE: {
        EN: "Profit per FTE",
        ES: "Ganancia por FTE",
    },
    TOTAL_PROFIT: {
        EN: "Total Profit",
        ES: "Ganancia Total",
    },
    ANNUAL_PROFIT_IMPACT: {
        EN: "Annual Profit Impact",
        ES: "Impacto Anual en las Ganancias",
    },
    SAVINGS_SUBMIT_MESSAGE: {
        EN: "When you are ready, click below to calculate.",
        ES: "Cuando esté listo, haga clic a continuación para calcular.",
    },
    BY_SUBMITTING_THIS_FORM: {
        EN: "By submitting this form, you agree to our",
        ES: "Al enviar este formulario, aceptas nuestro",
    },
    AND_AKNOWLEDGE_READING_OUR: {
        EN: "and acknowledge reading our",
        ES: "y reconoces haber leído nuestro",
    },
    YOU_ALSO_AGREE_TO_RECEIVE_APP_NOTIFICATIONS: {
        EN: "You also agree to receive App notification SMS messages to the provided phone number. Message and data rates may apply. Reply STOP to opt out.",
        ES: "También aceptas recibir mensajes SMS de notificación de la aplicación en el número de teléfono proporcionado. Pueden aplicarse tarifas de mensajes y datos. Responde STOP para cancelar la suscripción.",
    },
    JOB_POSTING_AGREEMENT: {
        EN: "Job Posting Agreement",
        ES: "Acuerdo de publicación de empleo",
    },
    USER_AGREEMENT: {
        EN: "User Agreement",
        ES: "Acuerdo de usuario",
    },
    USER_PRIVACY_NOTICE: {
        EN: "User Privacy Notice",
        ES: "Aviso de privacidad del usuario",
    },
    SAVINGS_TOTAL_TITLE: {
        EN: "Total",
        ES: "Total",
    },
    FTE_CHURN: {
        EN: "FTE Churn",
        ES: "FTE Churn",
    },
    FTE_CHURN_PERCENT: {
        EN: "FTE Churn",
        ES: "FTE Churn",
    },
    HIRES_FROM_REFERRALS_PERCENT: {
        EN: "Hires from Referrals",
        ES: "Contrataciones de referencias",
    },
    REDUCTION_IN_CHURN_PERCENT: {
        EN: "Reduction in Churn",
        ES: "Reducción de la deserción",
    },
    ONBOARDING_TRAINING_TESTING: {
        EN: "Onboarding/Training/Testing",
        ES: "Incorporación/Entrenamiento/Pruebas",
    },
    LOST_PROFIT: {
        EN: "Lost Profit (loss of ST billable profits due to churn)",
        ES: "Ganancia Perdida (pérdida de ganancias facturables de ST debido a la rotación)",
    },
    ANNUAL_COST_IMPACT: {
        EN: "Annual Cost Impact",
        ES: "Impacto de Costo Anual",
    },
    SUBSCRIPTION_COST: {
        EN: "WorkOn Subscription Cost",
        ES: "WorkOn costo de suscripción",
    },
    DEFAULT_SUBSCRIPTION_COST: {
        EN: "WorkOn Subscription Cost",
        ES: "Costo de subscripción de WorkOn",
    },
    CUSTOM_SUBSCRIPTION_COST: {
        EN: "Enter The Amount You Would Pay Today",
        ES: "Ingrese la cantidad que desea pagar hoy",
    },
    BOOK_A_DEMO: {
        EN: "Book a Demo",
        ES: "Reserve una demostración",
    },
    LIKE_WHAT_YOU_SEE: {
        EN: "Like what you see?",
        ES: "¿Te gusta lo que ves?",
    },
    SAVINGS_PER_REFERRAL_HIRE: {
        EN: "Savings per Referral Hire",
        ES: "Ahorros por contratación de referencia",
    },
    ANNUAL_SAVINGS: {
        EN: "Annual $ Savings",
        ES: "Ahorro $ Anual",
    },
    ANNUAL_SAVINGS_PERCENT: {
        EN: "Annual % Savings",
        ES: "% de ahorro anual",
    },
    COST_TO_HIRE_CHURNED_FTES: {
        EN: "Cost to Hire Churned FTEs without Referrals",
        ES: "Costo de contratar FTE cancelados sin referencias",
    },
    RECRUITING_AND_RETENTION_SAVINGS: {
        EN: "Recruiting and Retention Savings",
        ES: "Recruiting and Retention Savings",
    },
    AVG_RECRUITING_EXPENSE_SOURCE: {
        EN: "The average recruiting expense is $4,700 per hire, according to the ",
        ES: "El gasto promedio de reclutamiento es de $4,700 por contratación, según el ",
    },
    AVG_HOURLY_WAGE_SOURCE: {
        EN: "The average hourly wage for skilled workers is $19, according to ",
        ES: "El salario promedio por hora para trabajadores calificados es de $19, según ",
    },
    AVG_HOURLY_COST_OF_BENEFITS_SOURCE: {
        EN: "The average hourly cost of benefits for workers in the private industry is $10.76, according to ",
        ES: "El costo promedio por hora de los beneficios para los trabajadores en la industria privada es de $10.76, según ",
    },
    OFFERINGS_HEADER: {
        EN: "What does BoomNation offer?",
        ES: "¿Qué ofrece BoomNation?",
    },
    LANDING_CAROUSEL_ITEM_1: {
        EN: "Better than a job board",
        ES: "Mejor que una bolsa de trabajo",
    },
    LANDING_CAROUSEL_ITEM_2: {
        EN: "More than a recruiter",
        ES: "Más que un reclutador",
    },
    LANDING_CAROUSEL_ITEM_3: {
        EN: "A community built network",
        ES: "Una red construida por la comunidad",
    },
    WHY_BN_CAROUSEL_ITEM_1: {
        EN: "Recruiters. hate. job boards.",
        ES: "Reclutadores. odiar. bolsas de trabajo.",
    },
    WHY_BN_CAROUSEL_ITEM_2: {
        EN: "Workers. hate. job boards.",
        ES: "Trabajadores. odiar. bolsas de trabajo.",
    },
    OFFER_ITEM_1_TITLE: {
        EN: "Recruiting",
        ES: "Reclutamiento",
    },
    OFFER_ITEM_2_TITLE: {
        EN: "Community",
        ES: "Comunidad",
    },
    OFFER_ITEM_3_TITLE: {
        EN: "Communication",
        ES: "Comunicación",
    },
    OFFER_ITEM_1_DESCRIPTION: {
        EN: "Revolutionize your hiring strategy and fill positions 2x faster",
        ES: "Revolucione su estrategia de contratación y ocupe puestos 2 veces más rápido",
    },
    OFFER_ITEM_2_DESCRIPTION: {
        EN: "Social-networking for workers, by workers",
        ES: "Redes sociales para trabajadores, por trabajadores",
    },
    OFFER_ITEM_3_DESCRIPTION: {
        EN: "Direct line of communication betwen employers & workers",
        ES: "Línea directa de comunicación entre empleadores y trabajadores.",
    },
    WHY_BOOMNATION: {
        EN: "Why BoomNation",
        ES: "¿Por qué BoomNation?",
    },
    UNLIMITED_JOBS_PROMOTION: {
        EN: "Click here for unlimited job postings, $100 monthly, cancel anytime.",
        ES: "Haga clic aquí para publicaciones de empleo ilimitadas, $100 mensuales, cancele en cualquier momento.",
    },
    UNLIMITED_JOBS_TITLE: {
        EN: "Flat Fee. No Strings.",
        ES: "Tarifa plana. Sin compromisos.",
    },
    UNLIMITED_JOBS_SUBTITLE: {
        EN: "Unlimited Job Posts.",
        ES: "Publicaciones de Empleo Ilimitadas.",
    },
    UNLIMITED_JOBS_CONTENT_1: {
        EN: "Access Pre-Screened Skilled Candidates for Just $100/mo.",
        ES: "Acceda a candidatos calificados preseleccionados por solo $100 al mes.",
    },
    UNLOCK_NOW: {
        EN: "Unlock Now!",
        ES: "¡Desbloquea Ahora!",
    },
    JOB_BOARDS_STAT: {
        EN: "Job boards produce 88% of applicants, but only 29% of hires.",
        ES: "Las bolsas de trabajo producen el 88% de los solicitantes, pero sólo el 29% de las contrataciones.",
    },
    SOCIAL_RECRUITING_HEADER: {
        EN: "1. Social Recruiting",
        ES: "1. Reclutamiento social",
    },
    SOCIAL_RECRUITING_BODY: {
        EN: "Leveraging the power of networking and personalized interactions we have the capability to effectively match skilled job seekers from our community of 700k+ workers with the positions you're looking to fill.",
        ES: "Aprovechando el poder de las redes y las interacciones personalizadas, tenemos la capacidad de conectar de manera efectiva a los solicitantes de empleo calificados de nuestra comunidad de más de 700 mil trabajadores con los puestos que desea cubrir.",
    },
    INTERNAL_RECRUITING_HEADER: {
        EN: "2. Internal Recruiting",
        ES: "2. Reclutamiento interno",
    },
    INTERNAL_RECRUITING_BODY: {
        EN: "In addition to our social recruitment, we empower companies to harness the potential of internal recruiting through our software, WorkOn. Our SMS referral recruiting feature has shown a significantly reduced time-to-fill for positions and higher retention rates.",
        ES: "Además de nuestro reclutamiento social, capacitamos a las empresas para que aprovechen el potencial del reclutamiento interno a través de nuestro software, WorkOn. Nuestra función de reclutamiento por referencias por SMS ha mostrado un tiempo de ocupación de puestos significativamente reducido y tasas de retención más altas.",
    },
    COMMUNITY: {
        EN: "Community",
        ES: "Comunidad",
    },
    COMMUNITY_STAT: {
        EN: "The #1 Blue Collar Community in the United States with over 700k + Workers.",
        ES: "La comunidad laboral número uno en los Estados Unidos con más de 700 mil trabajadores.",
    },
    COMMUNITY_HEADER: {
        EN: "A community of workers from all trades",
        ES: "Una comunidad de trabajadores de todos los oficios.",
    },
    COMMUNITY_BODY: {
        EN: "Allowing workers to foster valuable connections, create networking opportunities, and exchange content, advice, and, mutual support with other members of the industry.",
        ES: "Permitir que los trabajadores fomenten conexiones valiosas, creen oportunidades de networking e intercambien contenido, consejos y apoyo mutuo con otros miembros de la industria.",
    },
    COMMUNICATION: {
        EN: "Communication",
        ES: "Comunicación",
    },
    COMMUNICATION_SUB_TEXT: {
        EN: "Workforce management straight to the workers phone...",
        ES: "Gestión de la fuerza laboral directamente al teléfono de los trabajadores...",
    },
    COMMUNICATION_BRIDGING_SUB_HEADER: {
        EN: "Bridging the gap between workers and employers",
        ES: "Bridging the gap between workers and employers",
    },
    COMMUNICATION_BRIDGING_SUB_TEXT: {
        EN: "86% of employees and executives cite the lack of effective collaboration and communication as the main causes for workplace failures.",
        ES: "86% of employees and executives cite the lack of effective collaboration and communication as the main causes for workplace failures.",
    },
    COMMUNICATION_IMG_HEADER_1: {
        EN: "With a simple click...",
        ES: "Con un simple clic...",
    },
    COMMUNICATION_IMG_HEADER_2: {
        EN: "Questions. Answered.",
        ES: "Preguntas. Contestada.",
    },
    COMMUNITY_TITLE: {
        EN: "The Biggest Blue Collar Community in the United States",
        ES: "La comunidad obrera más grande de los Estados Unidos",
    },
    COMMUNITY_DESCRIPTION: {
        EN: "BoomNation is committed to revolutionizing the skilled worker community by cultivating a movement that invests in the worker in a realistic “boots on the ground” adoption. We believe when workers are invested in, it will ultimately lead to the max industry efficiencies that are desperately needed.",
        ES: "BoomNation se compromete a revolucionar al trabajador calificado comunidad cultivando un movimiento que invierte en el trabajador en una adopción realista de “botas en el terreno”. Creemos cuando invierten los trabajadores, en última instancia conducirá a la máxima industria eficiencias que se necesitan desesperadamente.",
    },
    TRUST_COMPANIES_TITLES: {
        EN: "Trusted by companies all over the US",
        ES: "Con la confianza de empresas de todo EE. UU.",
    },
    DEEPER_DIVE: {
        EN: "Take a deeper dive into",
        ES: "Sumérgete más profundamente en",
    },
    TIME_TO_REVOLUTIONIZE: {
        EN: "Time to revolutionize your hiring strategy",
        ES: "Es hora de revolucionar su estrategia de contratación",
    },
    JOIN_THE_COMMUNITY: {
        EN: "Join the #1 Blue Collar Community",
        ES: "Únase a la comunidad laboral número uno",
    },
    JOIN_THE_COMMUNITY_BUTTON_LABEL: {
        EN: "Join The Community",
        ES: "Unete a la communidad",
    },
    EXPLORE: {
        EN: "Explore",
        ES: "Explorar",
    },
    CONTACT: {
        EN: "Contact",
        ES: "Contacto",
    },
    COMPANY_CAROUSEL_ITEM_1: {
        EN: "BoomNation's Core Devotion",
        ES: "La principal devoción de BoomNation",
    },
    WHO_WE_ARE: {
        EN: "Who We Are",
        ES: "Quienes somos",
    },
    OUR_FOCUS: {
        EN: "Our Focus",
        ES: "Nuestro objetivo",
    },
    OUR_ANTHEM: {
        EN: "Our Anthem",
        ES: "Nuestro himno",
    },
    OUR_CORE_VALUES: {
        EN: "Our Core Values",
        ES: "Nuestros valores fundamentales",
    },
    OUR_STORY: {
        EN: "Our Story",
        ES: "Nuestra historia",
    },
    OUR_ANTHEM_TEXT_1_LINE_1: {
        EN: "Here's to the roughnecks, the road warriors, those who create, inspire, serve to build our great country.",
        ES: "Brindemos por los matones, los guerreros del camino, aquellos que crean, inspiran y sirven para construir nuestro gran país.",
    },
    OUR_ANTHEM_TEXT_2_LINE_1: {
        EN: "To the selfless, who do the right thing when no one else is looking and there's no credit to be had",
        ES: "A los desinteresados, que hacen lo correcto cuando nadie más está mirando y no se les puede dar crédito. Los que tienen punta de acero, cabeza dura, ásperos y manos de papel de lija.",
    },
    OUR_ANTHEM_TEXT_2_LINE_2: {
        EN: "Steel toed, hard headed, rough ones with sandpaper hands.",
        ES: "Los que tienen punta de acero, cabeza dura, ásperos y manos de papel de lija.",
    },
    OUR_ANTHEM_TEXT_3_LINE_1: {
        EN: "To those who band together and who inspire one another to be proud of the part they play. To the ones who know once one job is done another one needs to be found, and fast, to keep their families fed and their bills paid.",
        ES: "A aquellos que se unen y se inspiran mutuamente a estar orgullosos del papel que desempeñan. A los quesaben que una vez realizado un trabajo es necesario encontrar otro, y rápido, para mantener a sus familias alimentadas y sus facturas pagadas.",
    },
    OUR_ANTHEM_TEXT_4_LINE_1: {
        EN: "To the forgotten workforce, the one who still has to wait in line and send faxes just to be ignored.  You are us and we are you - building, creating and inspiring.",
        ES: "A la fuerza laboral olvidada, la que todavía tiene que hacer cola y enviar faxes sólo para ser ignorada.",
    },
    OUR_ANTHEM_TEXT_4_LINE_2: {
        EN: "Well f*** that, no more waiting. We heard you loud and clear. We will always hear you.",
        ES: "Bueno, a la mierda eso, no más esperas. Te escuchamos alto y claro. Siempre te escucharemos.",
    },
    OUR_ANTHEM_TEXT_4_LINE_3: {
        EN: "You are us and we are you – building, creating and inspiring.",
        ES: "Tú eres nosotros y nosotros somos tú: construyendo, creando e inspirando.",
    },
    OUR_ANTHEM_TEXT_5_LINE_1: {
        EN: "We are one.",
        ES: "Nosotros somos uno",
    },
    OUR_ANTHEM_TEXT_5_LINE_2: {
        EN: "One culture, one company.",
        ES: "Una cultura, una empresa.",
    },
    OUR_ANTHEM_TEXT_5_LINE_3: {
        EN: "One nation, under BOOM!",
        ES: "¡Una nación, bajo BOOM!",
    },
    OUR_ANTHEM_TEXT_5_LINE_4: {
        EN: "WE ARE BOOMNATION. BUILT FOR WORKERS, BY WORKERS.",
        ES: "SOMOS BOOMNACIÓN. CONSTRUIDO PARA LOS TRABAJADORES, POR LOS TRABAJADORES.",
    },
    WHO_WE_ARE_TEXT: {
        EN: "BoomNation is a community driven by roughnecks, road warriors, and badass skilled tradesmen who create, inspire, serve and build our great country. In doing so, BoomNation leverages content, community and communications tools to efficiently connect employers and workers.",
        ES: "BoomNation es una comunidad impulsada por matones, guerreros de la carretera y comerciantes expertos y rudos que crean, inspiran, sirven y construyen nuestro gran país. Al hacerlo, BoomNation aprovecha el contenido, la comunidad y las herramientas de comunicación para conectar de manera eficiente a empleadores y trabajadores.",
    },
    OUR_FOCUS_CONTENT_LABEL: {
        EN: "Content",
        ES: "Contenido",
    },
    OUR_FOCUS_CONTENT_TEXT: {
        EN: "social-networking for workers, by workers",
        ES: "redes sociales para trabajadores, por trabajadores",
    },
    OUR_FOCUS_COMMUNITY_LABEL: {
        EN: "Community",
        ES: "Comunidad",
    },
    OUR_FOCUS_COMMUNITY_TEXT: {
        EN: "interactions and experiences between workers and employers",
        ES: "Interacciones y experiencias entre trabajadores y empleadores",
    },
    OUR_FOCUS_COMMUNICATION_LABEL: {
        EN: "Communication",
        ES: "Comunicación",
    },
    OUR_FOCUS_COMMUNICATION_TEXT: {
        EN: "worker to worker and employer to worker direct line of communication",
        ES: "Línea de comunicación directa de trabajador a trabajador y de empleador a trabajador",
    },
    OUR_FOCUS_JOBS_LABEL: {
        EN: "Jobs",
        ES: "Trabajos",
    },
    OUR_FOCUS_JOBS_TEXT: {
        EN: "effectively matching workers from our community with the positions you are looking to fill",
        ES: "hacer coincidir eficazmente a los trabajadores de nuestra comunidad con los puestos que desea cubrir",
    },
    FOCUS_LABEL: {
        EN: "Focus",
        ES: "Enfocar",
    },
    FOCUS_TEXT: {
        EN: "We are doers, we are resilient",
        ES: "Somos hacedores, somos resistentes",
    },
    INTEGRITY_LABEL: {
        EN: "Integrity",
        ES: "Integridad",
    },
    INTEGRITY_TEXT: {
        EN: "We do the right thing, especially when no one else is looking",
        ES: "Hacemos lo correcto, especialmente cuando nadie más está mirando",
    },
    INNOVATION_LABEL: {
        EN: "Innovation",
        ES: "Innovación",
    },
    INNOVATION_TEXT: {
        EN: "We listen, we create, we execute",
        ES: "Nosotros escuchamos, creamos, ejecutamos",
    },
    TEAMWORK_LABEL: {
        EN: "Teamwork",
        ES: "Trabajo en equipo",
    },
    TEAMWORK_TEXT: {
        EN: "We grow together, we win together",
        ES: "Crecemos juntos, ganamos juntos",
    },
    OUR_STORY_TEXT: {
        EN: "BoomNation was founded by a born and bred blue collar worker who saw a critical need in the skilled worker industry. Our founder discovered the skilled trades industry was missing a connection point between the worker and the employer, which hindered employers from finding talented tradesmen and workers from finding fulfilling work. What started as a simple idea has turned into a movement powered by a technology platform for skilled workers and employers, providing content, community, and communications to streamline job placement and efficiently connect employers and workers.",
        ES: "BoomNation fue fundada por un trabajador manual nacido y criado que vio una necesidad crítica en la industria de trabajadores calificados. Nuestro fundador descubrió que a la industria de los oficios calificados le faltaba un punto de conexión entre el trabajador y el empleador, lo que impedía a los empleadores encontrar comerciantes talentosos y a los trabajadores encontrar un trabajo satisfactorio. Lo que comenzó como una idea simple se ha convertido en un movimiento impulsado por una plataforma tecnológica para trabajadores calificados y empleadores, que brinda contenido, comunidad y comunicaciones para agilizar la colocación laboral y conectar de manera eficiente a empleadores y trabajadores.",
    },
    WORKER_LOG_IN: {
        EN: "Worker Log In",
        ES: "Trabajador Iniciar sesión",
    },
    LANDING_SUB_TEXT: {
        EN: "Recruit, manage and connect with the skilled workforce like never before",
        ES: "Reclute, administre y conéctese con fuerza laboral calificada como nunca antes",
    },
    HOW_IT_WORKS: {
        EN: "This is how it works:",
        ES: "Así es como funciona:",
    },
    TOP_PAYING_JOBS: {
        EN: "Top Paying Jobs.",
        ES: "Trabajos mejor pagados.",
    },
    FREE_AND_FAST: {
        EN: "Fast Preescreening Performed Through Text",
        ES: "Preselección Rápida Realizada Mediante Texto",
    },
    SAME_DAY_CALLS: {
        EN: "Quick Response via Text",
        ES: "Respuesta Rápida vía Texto",
    },
    NO_APPLICATIONS_NO_BS: {
        EN: "No BS!",
        ES: "Sin tonterías!",
    },
    ALL_THE_INFO: {
        EN: "Answer The Questions and We Will Be In Contact Soon.",
        ES: "Responde las Preguntas y Nos Pondremos en Contacto Pronto",
    },
    JOB_OPPORTUNITIES_AVAILABLE: {
        EN: "Job Opportunities Available RIGHT NOW.",
        ES: "Oportunidades de Empleo Disponibles AHORA MISMO.",
    },
};

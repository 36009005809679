import React from "react";

import AvatarImageInputPreview from "~shared/Form/Inputs/ImageUploadInput/AvatarImageInputPreview";
import ImageUploadInput from "~shared/Form/Inputs/ImageUploadInput/ImageUploadInput";

export default function AvatarImageInput(props) {
    return (
        <ImageUploadInput {...props} EmptyComponent={AvatarImageInputPreview} />
    );
}

/* eslint-disable max-lines */
// todo: port over new logic to lib-common form component and use here
import { get } from "@boomnation/lib-common";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
    FORM_INPUTS,
    TEST_IDS,
    FORM_SUBMIT_BUTTON_LABELS,
    FORM_CANCEL_BUTTON_LABELS,
} from "~constants/constants";
import Button from "~shared/Button/Button";
import { getIsHidden, getWidthOptions } from "~shared/Form/formHelpers";
import { InputWidth } from "~shared/Form/Inputs/styledFormComponents";
import PageSection from "~shared/Page/PageSection";

export const VALIDATION_MODES = {
    ON_BLUR: "onBlur",
    ON_SUBMIT: "onSubmit",
    ON_CHANGE: "onChange",
};

export default function Form({
    name,
    initialValues,
    isLoading,
    isDisabled,
    sections,
    isColumn,
    isCentered,
    validationSchema,
    validationMode = VALIDATION_MODES.ON_BLUR,
    onSubmit,
    onCancel,
    isButtonCentered = false,
    includeAsterisksOnRequiredFields = false,
    submitButtonLabel = FORM_SUBMIT_BUTTON_LABELS.DEFAULT,
    cancelButtonLabel = FORM_CANCEL_BUTTON_LABELS.DEFAULT,
}) {
    const { t } = useTranslation();
    const configs = getFormConfig({
        initialValues,
        validationSchema,
        validationMode,
    });

    const { control, handleSubmit, formState, watch, setValue } =
        useForm(configs);
    const { isSubmitting, errors } = formState;

    return (
        <>
            <FormContainer
                data-testid={TEST_IDS.FORM}
                isColumn={isColumn}
                isCentered={isCentered}
            >
                {name && <FormName variant="h4">{t(name)}</FormName>}
                {sections.map((section, idx) => (
                    <FormSection
                        key={`${section.title}-${idx}`}
                        title={section.title}
                        width={section.width}
                        noPadding={section.noPadding}
                        noMarginTop={section.noMarginTop}
                        noMarginBottom={section.noMarginBottom}
                        hideHeaderBorder={section.hideHeaderBorder}
                        isBackgroundVisible={section.isBackgroundVisible}
                    >
                        {section.subtitle && (
                            <Subtitle variant="h5">
                                {t(section.subtitle)}
                            </Subtitle>
                        )}
                        {section.subsections.map(
                            ({
                                title,
                                subtitle,
                                label,
                                description,
                                path,
                                referencePath,
                                Component,
                                onChange: onSubsectionChange,
                                id,
                                getComponentProps,
                                AddButtonComponent,
                                getAddButtonComponentProps,
                                isNestedForm,
                                ...config
                            }) => {
                                const { addBottomMargin } = config;
                                const isHidden = getIsHidden({
                                    config,
                                    values: watch(referencePath),
                                });
                                const {
                                    isHalfWidth,
                                    isQuarterWidth,
                                    isSixthWidth,
                                } = getWidthOptions({
                                    config,
                                    values: watch(referencePath),
                                });

                                const componentProps = getComponentProps
                                    ? getComponentProps(watch(referencePath))
                                    : {};

                                const validationMessage = getErrorMessage({
                                    errors,
                                    path,
                                    isNestedForm,
                                });

                                const formattedTitle = getFormattedTitle({
                                    title,
                                    path,
                                    validationSchema,
                                    includeAsterisksOnRequiredFields,
                                    t,
                                });

                                if (isHidden) return null;

                                return (
                                    <InputWidth
                                        key={`${title}-${path}`}
                                        isHalfWidth={isHalfWidth}
                                        isQuarterWidth={isQuarterWidth}
                                        isSixthWidth={isSixthWidth}
                                        addBottomMargin={addBottomMargin}
                                    >
                                        <Controller
                                            name={path}
                                            control={control}
                                            render={({
                                                field: {
                                                    value,
                                                    onChange,
                                                    onBlur,
                                                },
                                            }) => (
                                                <Component
                                                    {...componentProps}
                                                    id={id}
                                                    title={formattedTitle}
                                                    subtitle={t(subtitle)}
                                                    description={description}
                                                    label={label}
                                                    value={value}
                                                    onChange={(newValue) =>
                                                        handleOnChange({
                                                            newValue,
                                                            setValue,
                                                            onChange,
                                                            onSubsectionChange,
                                                        })
                                                    }
                                                    onBlur={onBlur}
                                                    validationMessage={
                                                        validationMessage
                                                    }
                                                />
                                            )}
                                        />
                                        {AddButtonComponent && (
                                            <AddButtonComponent
                                                {...getAddButtonComponentProps()}
                                            />
                                        )}
                                    </InputWidth>
                                );
                            }
                        )}
                    </FormSection>
                ))}
            </FormContainer>
            <ButtonBox isButtonCentered={isButtonCentered}>
                {onCancel && (
                    <CancelButton
                        loading={isLoading || isSubmitting}
                        variant="outlined"
                        onClick={onCancel}
                    >
                        {t(cancelButtonLabel)}
                    </CancelButton>
                )}
                <SubmitButton
                    data-testid={FORM_INPUTS.BUTTON}
                    loading={isLoading || isSubmitting}
                    disabled={isDisabled}
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                >
                    {t(submitButtonLabel)}
                </SubmitButton>
            </ButtonBox>
        </>
    );
}

Form.propTypes = {
    name: PropTypes.string,
    isColumn: PropTypes.bool,
    isCentered: PropTypes.bool,
    initialValues: PropTypes.shape({}),
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            subsections: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    path: PropTypes.string,
                    onChange: PropTypes.func,
                    Component: PropTypes.elementType,
                    AddButtonComponent: PropTypes.elementType,
                    isHalfWidth: PropTypes.oneOfType([
                        PropTypes.bool,
                        PropTypes.func,
                    ]),
                    isHidden: PropTypes.oneOfType([
                        PropTypes.bool,
                        PropTypes.func,
                    ]),
                })
            ),
        })
    ).isRequired,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    validationSchema: PropTypes.shape({}),
    validationMode: PropTypes.string,
    ButtonComponent: PropTypes.func,
    isButtonCentered: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    submitButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    includeAsterisksOnRequiredFields: PropTypes.bool,
};

function getFormConfig({ initialValues, validationSchema, validationMode }) {
    return {
        defaultValues: initialValues,
        ...(validationSchema && { resolver: yupResolver(validationSchema) }),
        mode: validationMode,
        reValidateMode: validationMode,
    };
}

function getErrorMessage({ errors, path, isNestedForm }) {
    const allErrors = get(errors, path);
    if (!allErrors) return undefined;

    // return error if single error
    if (allErrors.message) return getMessageOrObject(allErrors);

    // only return single error if object shape has errors
    if (!Array.isArray(allErrors)) {
        const error = isNestedForm ? allErrors : Object.values(allErrors)[0];

        return getMessageOrObject(error);
    }

    // only return a single error at a time if array of errors
    const filteredErrors = allErrors.filter((error) => error);

    return getMessageOrObject(filteredErrors[0]);
}

function getMessageOrObject(error) {
    return error.message ? error.message : error;
}

function handleOnChange({ newValue, setValue, onChange, onSubsectionChange }) {
    onChange(newValue);

    if (onSubsectionChange) {
        onSubsectionChange({
            newValue,
            setValue,
        });
    }
}

function getFormattedTitle({
    validationSchema,
    path,
    title,
    t,
    includeAsterisksOnRequiredFields,
}) {
    const translatedTitle = t(title);
    const isFieldRequired =
        validationSchema?.fields?.[path]?.exclusiveTests?.required;
    const shouldAddAsterick =
        includeAsterisksOnRequiredFields && isFieldRequired;

    return shouldAddAsterick ? `${translatedTitle} *` : translatedTitle;
}

const FormContainer = styled(Box, { shouldForwardProp: isPropValid })(
    ({ isColumn, isCentered }) => ({
        width: "100%",
        ...(isColumn && {
            display: "flex",
            flexDirection: "row",
            justifyContent: isCentered ? "center" : "space-between",
        }),
    })
);

const FormName = styled(Typography)({
    marginLeft: "16px",
    marginBottom: "25px",
});

const SubmitButton = styled(Button)({
    padding: "10px 40px",
    width: "100%",
    maxWidth: "400px",
});

const FormSection = styled(PageSection, {
    shouldForwardProp: (prop) => prop !== "noPadding",
})(({ width }) => ({
    ...(width && {
        paddingRight: 10,
    }),
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
}));

const ButtonBox = styled(Box, { shouldForwardProp: isPropValid })(
    ({ isButtonCentered }) => ({
        display: "flex",
        justifyContent: isButtonCentered ? "center" : "flex-end",
        flexDirection: "row",
        marginTop: 20,
        marginBottom: 20,
    })
);

const CancelButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(2),
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    margin: "5px 8px 25px 8px",
    fontWeight: "500",
}));
